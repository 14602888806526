import { Alert } from "@mui/material";
import { toArray } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetTrailerDetailsQuery,
  useDeleteTrailerMutation,
  useGetTrailerDetailsQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import TrailerDetails from "./TrailerDetails";
import { useTranslation } from "react-i18next";

type TrailerDetailsContainerProps = {
  trailerId: string;
  onLoad?: (trailer: GetTrailerDetailsQuery["trailerById"]) => void;
};

function TrailerDetailsContainer({
  trailerId,
  onLoad,
}: TrailerDetailsContainerProps) {
  const { t } = useTranslation(["assets", "common"]);
  const query = useGetTrailerDetailsQuery({
    id: trailerId,
  });
  const deleteTrailerMutation = useDeleteTrailerMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.data?.trailerById) {
      onLoad?.(query.data?.trailerById);
    }
  }, [onLoad, query.data]);

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        {t("common:error.fetchError", "An error occurred while fetching")}.{" "}
        {(query.error as Error).message ||
          t("common:error.unknownError", "Unknown error")}
      </Alert>
    );
  }
  if (!toArray(query.data)[0]) {
    return (
      <Alert severity="error">{t("common:error.notFound", "Not found")}</Alert>
    );
  }
  return (
    <TrailerDetails
      trailer={toArray(query.data)[0]}
      onEdit={() => {
        navigate(`/trailers/${trailerId}/edit`);
      }}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: t("common:error.dangerZone", "Danger zone"),
            description: t(
              "error.deleteTrailerConfirmation",
              "Do you really want to delete this trailer? This action cannot be undone."
            ),
            type: "error",
            actions: [
              {
                type: "error",
                title: t("common:error.yesDelete", "Yes, Delete"),
                onClick: async () => {
                  try {
                    await deleteTrailerMutation.mutateAsync({
                      id: trailerId,
                    });
                    dispatch(hideDialog());
                    navigate("/trailers");
                  } catch (error) {
                    console.error(error);
                    dispatch(
                      showDialog({
                        title: t("common:error.title", "Error"),
                        description:
                          t(
                            "deleteTrailerError",
                            "An error occurred while deleting the trailer."
                          ) + (error as Error).message ||
                          t("common:error.unknownError", "Unknown error"),
                      })
                    );
                  }
                },
              },
              {
                type: "primary",
                title: t("common:error.noCancel", "No, Cancel"),
              },
            ],
          })
        );
      }}
    />
  );
}

export default TrailerDetailsContainer;
