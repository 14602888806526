import { SelectProps } from "@mui/material";
import { ConditionValueSelectorComponentProps } from "../ConditionForm";
import EnumSelect from "../../../common/EnumSelect";
import { useTranslation } from "react-i18next";
import { invert } from "lodash";
import enumLabel from "../../../../utils/labels/enumLabel";

enum FuelCostArea {
  US = "NUS",
  PADD_1 = "R10",
  PADD_1A = "R1X",
  PADD_1B = "R1Y",
  PADD_1C = "R1Z",
  PADD_2 = "R20",
  PADD_3 = "R30",
  PADD_4 = "R40",
  PADD_5 = "R50",
  PADD_5_EXCEPT_CALIFORNIA = "R5XCA",
  CALIFORNIA = "SCA",
}

const FuelCostAreaSelect = ({
  value,
  onChange,
  ...otherProps
}: ConditionValueSelectorComponentProps & Pick<SelectProps<string>, "sx">) => {
  const { t } = useTranslation("billingRules");
  return (
    <EnumSelect
      label={otherProps.label}
      size="small"
      enumObject={FuelCostArea}
      value={(value as FuelCostArea) || null}
      onChange={(event, value) => {
        onChange(value);
      }}
      optionLabel={(option) =>
        enumLabel(invert(FuelCostArea)[option])?.toUpperCase() || option
      }
      withNoneOption
      noneOptionLabel={t("nationalUs", "National U.S.")}
      {...otherProps}
    />
  );
};

export default FuelCostAreaSelect;
