import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetShipmentQuery,
  useGetShipmentQuery,
  useUpdateShipmentMutation,
} from "../../../graphql/generated";
import { editShipment } from "../../../redux/slices/shipment/ShipmentForm.slice";
import {
  NewShipmentInputData,
  ShipmentLocationInputData,
} from "../../../redux/slices/Types";
import EditShipmentForm from "./EditShipmentForm";
import { omit } from "lodash";
import { v4 as uuid } from "uuid";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import { formatInTimeZone } from "date-fns-tz";
import { fallbackTimezone } from "../../../utils/labels/formatDateTime";
import { RootState } from "../../../redux/store";
import LoadingOverlay from "../../common/LoadingOverlay";
import { getPredefinedFromRecurrence } from "../ShipmentForm/ShipmentRecurrenceForm/ShipmentRecurrenceForm";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

type ShipmentDetails = GetShipmentQuery["shipmentById"];

export const toShipmentInputData = (
  shipment: ShipmentDetails
): NewShipmentInputData => {
  const convertedShipmentLocations: ShipmentLocationInputData[] = (
    shipment.shipmentLocations || []
  ).map((location) => {
    return {
      ...location,
      _id: location._id || uuid(),
      receivedGoods: location.receivedGoods || [],
      receiver: location.receiver?._id,
      shippedGoods: location.shippedGoods || [],
      shipper: location.shipper?._id,
    };
  });

  const firstLocationWithTimeWindow = shipment.shipmentLocations.filter(
    (location) => location.timeWindows.length
  )[0];

  return {
    ...shipment,
    documents: (shipment.documents || []).map((document) => ({
      ...document,
      receiver: document.receiver?._id,
      shipper: document.shipper?._id,
    })),
    notes: shipment.notes?.map((note) => ({
      ...note,
      receiver: note.receiver?._id,
      shipper: note.shipper?._id,
    })),
    charges: shipment.charges.map((charge) => ({
      ...omit(charge, "total"),
      billingRule: charge.billingRule?._id,
    })),
    customer: shipment?.customer?._id,
    shipmentLocations: convertedShipmentLocations,
    id: shipment._id,
    date: firstLocationWithTimeWindow
      ? formatInTimeZone(
          new Date(firstLocationWithTimeWindow.timeWindows[0].fromDate),
          firstLocationWithTimeWindow.shipper?.addressTimezone ||
            firstLocationWithTimeWindow.receiver?.addressTimezone ||
            fallbackTimezone,
          "yyyy-MM-dd"
        )
      : "",
    predefinedRecurrence: getPredefinedFromRecurrence(shipment.recurrence),
    route: null,
  };
};

type EditShipmentFormContainerProps = {
  onLoad?: (shipment: ShipmentDetails) => void;
};

const EditShipmentFormContainer = ({
  onLoad,
}: EditShipmentFormContainerProps) => {
  const { t } = useTranslation(["orders", "common"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false);
  const shipmentQuery = useGetShipmentQuery(
    { id: id || "" },
    {
      refetchOnWindowFocus: false,
    }
  );
  const shipment: ShipmentDetails | undefined =
    shipmentQuery.data?.shipmentById;
  const dispatch = useDispatch();
  const stateShipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );
  useEffect(() => {
    if (shipment) {
      dispatch(editShipment(toShipmentInputData(shipment)));
    }
  }, [shipment, dispatch]);

  useEffect(() => {
    if (shipment) {
      onLoad?.(shipment);
    }
  }, [shipment, onLoad]);
  const { mutateAsync: updateShipment, isLoading: updatingShipment } =
    useUpdateShipmentMutation({});

  if (!stateShipment.id) {
    return <LoadingOverlay loading />;
  }

  return !shipment?.isSplit ? (
    <EditShipmentForm
      loading={
        isUploadingDocuments || updatingShipment || shipmentQuery.isLoading
      }
      isChildLoad={!!shipment?.parentShipment}
      isSplit={!!shipment?.isSplit}
      isFromSplit={!!shipment?.isFromSplit}
      onSubmit={async (_shipment) => {
        try {
          if (!id || !_shipment) {
            return;
          }
          // if (newDocumentsToUpload) {
          //   setIsUploadingDocuments(true);
          //   await Promise.all(
          //     newDocumentsToUpload.map(async (d) => {
          //       await fetch(d.url, {
          //         method: "PUT",
          //         body: d.file,
          //       });
          //     })
          //   );
          //   setIsUploadingDocuments(false);
          // }
          await updateShipment({
            editShipmentData: {
              ...omit(_shipment, [
                "_id",
                "status",
                "shipmentNumber",
                "tripId",
                "reasonForCancellation",
              ]),
              charges: _shipment.charges.map((charge) =>
                omit(charge, "total", "_id")
              ),
              shipmentLocations: _shipment.shipmentLocations,
              expenses: (_shipment.expenses || []).map((expense) =>
                omit(expense, "_id")
              ),
            },
            id,
          });
          navigate(`/orders/details/${id}`);
        } catch (error) {
          dispatch(
            showDialog({
              type: "error",
              title: t(
                "error.createShipmentError",
                "Error while creating shipment"
              ),
              description:
                (error as Error).message ||
                t("common:error.unknownError", "Unknown error"),
            })
          );
        } finally {
          setIsUploadingDocuments(false);
        }
      }}
    />
  ) : (
    <Alert severity="error">
      {t("error.impossibleEditOrder", "Impossible to edit this order.")}
    </Alert>
  );
};

export default EditShipmentFormContainer;
