import { Stack, TextField } from "@mui/material";
import {
  GetInvoiceDetailsQuery,
  PaymentMethod,
} from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";
import { DatePicker } from "@mui/x-date-pickers";
import EnumSelect from "../../common/EnumSelect";
import { useTranslation } from "react-i18next";

export type InvoicePaymentData = NonNullable<
  GetInvoiceDetailsQuery["invoiceById"]["payments"]
>[0];

type InvoicePaymentFormProps = {
  payment: InvoicePaymentData;
  onChange: (payment: InvoicePaymentData) => void;
};

export default function InvoicePaymentForm({
  payment,
  onChange,
}: InvoicePaymentFormProps) {
  const { t } = useTranslation("finance");

  const handleChange = (update: Partial<InvoicePaymentData>) => {
    onChange({
      ...payment,
      ...update,
    });
  };

  return (
    <Stack spacing={2}>
      <Stack
        component="form"
        sx={{ display: "flex" }}
        direction="column"
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <NumberTextField
          name="amount"
          type="number"
          value={String(payment.amount) || ""}
          onChange={(event) => {
            handleChange({
              amount: parseFloat(event.target.value),
            });
          }}
          size="small"
          label={t("finance:amount", "Amount")}
        />

        <DatePicker
          value={payment.date}
          onChange={(date: Date | null) => {
            handleChange({
              date,
            });
          }}
          label={t("date", "Date")}
          InputProps={{
            size: "small",
            name: "date",
          }}
          renderInput={(params) => <TextField {...params} />}
        />

        <EnumSelect
          enumObject={PaymentMethod}
          value={payment.paymentMethod}
          onChange={(e, paymentMethod) => {
            handleChange({
              paymentMethod,
            });
          }}
          label={t("finance:paymentMethod", "Payment method")}
          InputProps={{
            size: "small",
          }}
        />

        <TextField
          name="note"
          value={payment.note || ""}
          onChange={(event) => {
            handleChange({
              note: event.target.value,
            });
          }}
          size="small"
          label={t("finance:note", "Note")}
        />
      </Stack>
    </Stack>
  );
}
