import { useEffect, useRef, useState } from "react";
import {
  PurchaseInput,
  useRankSupplierContractsForPurchaseMutation,
  useRankSupplierContractsForPurchasesMutation,
} from "../../../graphql/generated";
import BestBuyRankingList, {
  BestBuyRankingListItem,
  BestBuyRankingListProps,
} from "./BestBuyRankingList";
import { isArray } from "lodash";
import LoadingOverlay from "../../common/LoadingOverlay";

export type BestBuyRankingListContainerProps = Omit<
  BestBuyRankingListProps,
  "list" | "defaultSelected"
> & {
  purchase: PurchaseInput | PurchaseInput[];
};

export default function BestBuyRankingListContainer(
  props: BestBuyRankingListContainerProps
) {
  const [rankingList, setRankingList] = useState<BestBuyRankingListItem[]>([]);

  const rankSupplierContractsForPurchaseMutation =
    useRankSupplierContractsForPurchaseMutation();
  const rankSupplierContractsForPurchases =
    useRankSupplierContractsForPurchasesMutation();

  const initialPurchaseRef = useRef(props.purchase);

  useEffect(() => {
    async function fetchRankings() {
      const rankingList = isArray(initialPurchaseRef.current)
        ? await rankSupplierContractsForPurchases.mutateAsync({
            purchases: initialPurchaseRef.current,
          })
        : await rankSupplierContractsForPurchaseMutation.mutateAsync({
            purchase: initialPurchaseRef.current,
          });
      setRankingList(
        "rankSupplierContractsForPurchase" in rankingList
          ? rankingList.rankSupplierContractsForPurchase
          : rankingList.rankSupplierContractsForPurchases
      );
    }

    fetchRankings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    rankSupplierContractsForPurchaseMutation.isLoading ||
    rankSupplierContractsForPurchases.isLoading
  ) {
    return <LoadingOverlay loading />;
  }

  return (
    <BestBuyRankingList
      {...props}
      list={rankingList}
      defaultSelected={rankingList[0]}
      loading={
        rankSupplierContractsForPurchaseMutation.isLoading ||
        rankSupplierContractsForPurchases.isLoading
      }
    />
  );
}
