import { Box, Button, Stack } from "@mui/material";
import { GetLoadBoardLoadDetailsQuery } from "../../../graphql/generated";
import EmptyState from "../../common/EmptyState";
import ShipmentGeneralInfos from "../../shipment/ShipmentDetails/ShipmentGeneralInfos";

export type LoadBoardLoadDetailsProps = {
  load: GetLoadBoardLoadDetailsQuery["loadBoardLoadById"];
  onImport: () => void;
};

const LoadBoardLoadDetails = ({
  load,
  onImport,
}: LoadBoardLoadDetailsProps) => {
  if (!load) {
    return <EmptyState message="Order not found" />;
  }
  return (
    <Box>
      <ShipmentGeneralInfos shipment={load} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{
          mt: 2,
        }}
      >
        <Button variant="contained" onClick={() => onImport()} id="import-load">
          Import Order
        </Button>
      </Stack>
    </Box>
  );
};

export default LoadBoardLoadDetails;
