import { Alert } from "@mui/material";
import { toArray } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetGoodProfileDetailsQuery,
  useDeleteGoodProfileMutation,
  useGetGoodProfileDetailsQuery,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import GoodProfileDetails from "./GoodProfileDetails";

type GoodProfileDetailsContainerProps = {
  goodProfileId: string;
  onLoad?: (goodProfile: GetGoodProfileDetailsQuery["goodProfileById"]) => void;
};

function GoodProfileDetailsContainer({
  goodProfileId,
  onLoad,
}: GoodProfileDetailsContainerProps) {
  const query = useGetGoodProfileDetailsQuery({
    id: goodProfileId,
  });
  const deleteGoodProfileMutation = useDeleteGoodProfileMutation();
  const goodProfilesQuery = useGetGoodProfileListQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.data?.goodProfileById) {
      onLoad?.(query.data?.goodProfileById);
    }
  }, [onLoad, query.data]);

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        An error occurred while fetching.{" "}
        {(query.error as Error).message || "Unknown error"}
      </Alert>
    );
  }
  if (!toArray(query.data)[0]) {
    return <Alert severity="error">Not found</Alert>;
  }
  return (
    <GoodProfileDetails
      goodProfile={toArray(query.data)[0]}
      goodProfiles={goodProfilesQuery.data?.goodProfiles?.data || []}
      onEdit={() => {
        navigate(`/good-profiles/${goodProfileId}/edit`);
      }}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: "Danger zone",
            description:
              "Do you really want to delete this commodity? This action cannot be undone.",
            type: "error",
            actions: [
              {
                type: "error",
                title: "Yes, Delete",
                onClick: async () => {
                  try {
                    await deleteGoodProfileMutation.mutateAsync({
                      id: goodProfileId,
                    });
                    dispatch(hideDialog());
                    navigate("/good-profiles");
                  } catch (error) {
                    console.error(error);
                    dispatch(
                      showDialog({
                        title: "Error",
                        description:
                          "An error occurred while deleting the commodity. " +
                            (error as Error).message || "Unknown error",
                      })
                    );
                  }
                },
              },
              {
                type: "primary",
                title: "No, Cancel",
              },
            ],
          })
        );
      }}
    />
  );
}

export default GoodProfileDetailsContainer;
