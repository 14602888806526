import { isArray, isEmpty, isEqual, isObject, set, transform } from "lodash";

export default function getChangedProperties(
  original: Record<string, any>,
  updated: Record<string, any>
): Record<string, { oldValue: any; newValue: any }> {
  return transform(
    original,
    (result, value, key) => {
      // If value is an object, compare recursively
      if (isObject(value) && !isArray(value)) {
        const changes = getChangedProperties(value, updated[key]);
        if (!isEmpty(changes)) {
          set(result, key, changes);
        }
      } else if (!isEqual(value, updated[key])) {
        // If values are different, store the old and new values
        // @ts-expect-error
        result[key] = { oldValue: value, newValue: updated[key] };
      }
    },
    {}
  );
}
