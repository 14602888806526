import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  FieldErrors,
  FormError,
  NewShipmentInputData,
} from "../../../redux/slices/Types";
import LynksTabs from "../../common/LynksTabs";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GetCustomerDetailsQuery,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import { ExpandMore, RemoveCircle } from "@mui/icons-material";
import ReceiverFormContainer from "./ReceiverForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { useDispatch } from "react-redux";
import {
  addReceiver,
  removeShipperOrReceiver,
  setGoodProfiles,
} from "../../../redux/slices/shipment/ShipmentForm.slice";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import ShipmentReferencesForm from "./ShipmentReferencesForm/ShipmentReferencesForm";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import TrailerRequirementsFormContainer from "./TrailerRequirementsForm/TrailerRequirementsFormContainer";
import { isDropoffLocation } from "../../../utils/location/isPickupLocation";
import ShipmentRecurrenceForm from "./ShipmentRecurrenceForm/ShipmentRecurrenceForm";

type CommodityDrivenShipmentFormProps = {
  shipmentInput: NewShipmentInputData;
  //   shipmentDetails: ShipmentDetailsProps["shipment"] | null;
  errors?: FieldErrors;
  shipmentLocationErrors?: FormError[];
  loading: boolean;
  validating: boolean;
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  onChange: (name: string, value: string | null | undefined) => void;
  onValidate: () => Promise<boolean>;
  onSubmit: () => Promise<void>;
  isChildLoad?: boolean;
  isSplit?: boolean;
  isEdit?: boolean;
  customerDetails?: GetCustomerDetailsQuery["customerById"];
};

enum ShipmentFormTabs {
  Shipment = "shipment",
  References = "references",
}

const CommodityDrivenShipmentForm = (
  props: CommodityDrivenShipmentFormProps
) => {
  const { t } = useTranslation(["orders", "common"]);

  const tabs = [
    {
      label: t("orders:shipment", "Shipment"),
      value: ShipmentFormTabs.Shipment,
    },
    {
      label: t("orders:references", "References"),
      value: ShipmentFormTabs.References,
    },
  ];

  const [activeTab, setActiveTab] = useState(ShipmentFormTabs.Shipment);
  const receiverLocations =
    props.shipmentInput.shipmentLocations.filter(isDropoffLocation);

  const goodProfilesQuery = useGetGoodProfileListQuery();

  const goodProfiles = useMemo(
    () => goodProfilesQuery.data?.goodProfiles.data || [],
    [goodProfilesQuery]
  );

  const dispatch = useDispatch();

  const handleAddReceiverClick = () => {
    dispatch(addReceiver());
  };

  useEffect(() => {
    loadGoodProfiles();

    async function loadGoodProfiles() {
      const goodProfilesResponse = await useGetGoodProfileListQuery.fetcher()();

      const goodProfiles = goodProfilesResponse.goodProfiles.data || [];

      dispatch(setGoodProfiles(goodProfiles));
    }
  }, [dispatch]);

  const handleRemoveShipperOrReceiver = (type: string, id: string) => {
    dispatch(
      showDialog({
        title: t("common:error.dangerZone", "Danger zone"),
        description: t(
          `error.deleteShipperReceiverConfirmation.${type}`,
          `Do you really want to delete this ${type} ? This action cannot be undone.`
        ),
        type: "error",
        actions: [
          {
            type: "error",
            title: t("common:error.yesDelete", "Yes, Delete"),
            onClick: async () => {
              dispatch(removeShipperOrReceiver(id));
              dispatch(hideDialog());
            },
          },
          {
            type: "primary",
            title: t("common:error.noCancel", "No, Cancel"),
          },
        ],
      })
    );
  };

  const handleSelectTab = useCallback((tab: string) => {
    setActiveTab(tab as ShipmentFormTabs);
  }, []);

  const goodProfilesShippedGoods = useMemo(
    () =>
      goodProfiles.map((goodProfile) => ({
        _id: goodProfile._id,
        label: goodProfile.label,
        quantity: Infinity,
        goodProfileId: goodProfile._id,
        unit: goodProfile.unit,
        weight: goodProfile.weight,
      })),
    [goodProfiles]
  );

  return (
    <Container
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <LynksTabs tabs={tabs} value={activeTab} onSelectTab={handleSelectTab} />

      <Stack justifyContent="space-between" flex={1}>
        <Box
          display={activeTab === ShipmentFormTabs.Shipment ? undefined : "none"}
        >
          <Accordion
            sx={{
              mb: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Trailer Requirements</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TrailerRequirementsFormContainer
                shipment={props.shipmentInput}
                onUpdate={props.onUpdate}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              mb: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Recurrence</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShipmentRecurrenceForm
                shipment={props.shipmentInput}
                onUpdate={props.onUpdate}
                isChildLoad={props.isChildLoad}
                validationResult={null}
              />
            </AccordionDetails>
          </Accordion>

          {receiverLocations.map((receiverLocation, index) => (
            <Box
              key={`${receiverLocation._id}-${index}`}
              sx={{ position: "relative" }}
            >
              {receiverLocations.length > 1 && (
                <IconButton
                  sx={{ position: "absolute", right: -30, top: -30 }}
                  color="default"
                  onClick={() =>
                    handleRemoveShipperOrReceiver(
                      "receiver",
                      receiverLocation._id
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              )}
              <ReceiverFormContainer
                shippedGoods={goodProfilesShippedGoods}
                receivedGoods={receiverLocation.receivedGoods}
                key={index}
                receiver={receiverLocation}
                inline
                errors={props.shipmentLocationErrors?.find(
                  (err) => err.formId === receiverLocation._id
                )}
                allowAddressLocation={false}
              />
              <Divider
                sx={{
                  my: 3,
                }}
              />
            </Box>
          ))}
          <LabeledAddButton
            sx={{ height: 50, width: 20 }}
            onClick={handleAddReceiverClick}
            label={t("orders:addReceiver", "Add Receiver")}
          />
        </Box>
        {activeTab === ShipmentFormTabs.References ? (
          <Box>
            <ShipmentReferencesForm
              shipment={props.shipmentInput}
              onUpdate={props.onUpdate}
              errors={props.errors}
            />
          </Box>
        ) : null}

        <Stack justifyContent="flex-end" direction="row">
          <LoadingButton
            loading={props.validating || props.loading}
            onClick={async () => {
              if (await props.onValidate()) {
                await props.onSubmit();
              }
            }}
            variant="contained"
            size="large"
          >
            {t("common:save", "Save")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Container>
  );
};

export default CommodityDrivenShipmentForm;
