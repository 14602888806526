import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewGoodProfileInput,
  GoodUnits,
  BusinessEntityType,
  GetGoodProfileListQuery,
  GoodProfileEquivalenceConditionTarget,
  GoodProfileEquivalenceConditionOperator,
  GoodProfileRestrictionType,
  CustomFieldContext,
} from "../../../graphql/generated";
import { isArray, mergeWith, omit, without } from "lodash";
import goodProfileSchema from "./goodProfileSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import NumberTextField from "../../common/NumberTextField";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import BusinessEntitySelectContainer from "../../asset-management/BusinessEntitySelect";
import GoodProfileEquivalenceForm from "./GoodProfileEquivalenceForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Delete, RemoveCircle } from "@mui/icons-material";
import GoodProfileRestrictionForm from "./GoodProfileRestrictionForm";
import { kgToPounds, poundsToKg } from "../../../utils/conversion/weight";
import { useTranslation } from "react-i18next";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../../asset-management/GroupSelect";
import DocumentsUploadModalContainer from "../../common/DocumentsUploadModal";

type GoodProfileFormProps = {
  initialGoodProfile?: NewGoodProfileInput;
  saving: boolean;
  onSave: (goodProfile: NewGoodProfileInput) => void;
  goodProfiles: GetGoodProfileListQuery["goodProfiles"]["data"];
};
type PartialGoodProfile = Partial<NewGoodProfileInput>;
type DeepPartialGoodProfile = {
  [key in keyof PartialGoodProfile]: Partial<PartialGoodProfile[key]>;
};

const GoodProfileForm = ({
  initialGoodProfile,
  saving,
  onSave,
  goodProfiles,
}: GoodProfileFormProps) => {
  const { t } = useTranslation(["common", "assets"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewGoodProfileInput> | null>(null);
  const [localGoodProfile, setLocalGoodProfile] = useState<
    Partial<NewGoodProfileInput>
  >(initialGoodProfile || {});
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialGoodProfile) => {
    setLocalGoodProfile((localGoodProfile) =>
      mergeWith({}, localGoodProfile, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = goodProfileSchema.validate(
      omit(localGoodProfile, "_id"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localGoodProfile]);

  const { cancelConfirm } = useConfirmBeforeLeave(localGoodProfile);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t(
              "assets:commodity.profileDetails",
              "Commodity profile details"
            )}
          />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("assets:commodity.code", "Commodity Code")}
                name="code"
                required
                fullWidth
                value={localGoodProfile?.code || ""}
                error={!!getFieldError("code")}
                helperText={getFieldError("code")}
                onChange={(event) => {
                  onChange({ code: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:commodity.label", "Label")}
                name="label"
                fullWidth
                value={localGoodProfile?.label || ""}
                error={!!getFieldError("label")}
                helperText={getFieldError("label")}
                onChange={(event) => {
                  onChange({ label: event.target.value });
                }}
                required
              />
            </Grid>
            <Grid item sm={12}>
              <EnumSelect
                label={t("assets:commodity.unit", "Unit")}
                name="unit"
                fullWidth
                enumObject={GoodUnits}
                error={!!getFieldError("unit")}
                helperText={getFieldError("unit")}
                value={localGoodProfile?.unit}
                onChange={(event, unit) => {
                  if (!unit) {
                    return;
                  }
                  onChange({
                    unit,
                    weight: 0,
                    liquidGravity: null,
                  });
                }}
                required
              />
            </Grid>
            <Grid item sm={12}>
              <NumberTextField
                label={`${t("assets:commodity.weight", "Weight")} ${
                  localGoodProfile?.unit === GoodUnits.Liters
                    ? "(kg/L)"
                    : localGoodProfile?.unit === GoodUnits.Kilograms
                    ? "(kg)"
                    : localGoodProfile?.unit === GoodUnits.Gallons
                    ? "(lb/gal)"
                    : localGoodProfile?.unit === GoodUnits.Pounds
                    ? "(lb)"
                    : ""
                }`}
                name="weight"
                fullWidth
                value={
                  localGoodProfile?.weight
                    ? localGoodProfile.unit === GoodUnits.Gallons ||
                      localGoodProfile.unit === GoodUnits.Pounds
                      ? String(
                          parseFloat(
                            kgToPounds(localGoodProfile.weight).toFixed(2)
                          )
                        )
                      : String(localGoodProfile.weight)
                    : ""
                }
                error={!!getFieldError("weight")}
                helperText={getFieldError("weight")}
                onChange={(event) => {
                  const weight =
                    localGoodProfile?.unit === GoodUnits.Gallons ||
                    localGoodProfile?.unit === GoodUnits.Pounds
                      ? poundsToKg(parseFloat(event.target.value))
                      : parseFloat(event.target.value);
                  onChange({
                    weight,
                    liquidGravity:
                      localGoodProfile?.unit === GoodUnits.Liters
                        ? weight / 1
                        : localGoodProfile?.unit === GoodUnits.Gallons
                        ? kgToPounds(weight) / 8.34
                        : null,
                  });
                }}
              />
            </Grid>
            {localGoodProfile?.unit &&
            [GoodUnits.Liters, GoodUnits.Gallons].includes(
              localGoodProfile.unit
            ) ? (
              <Grid item sm={12}>
                <NumberTextField
                  label="Gravity"
                  name="liquidGravity"
                  fullWidth
                  value={String(
                    localGoodProfile?.liquidGravity
                      ? parseFloat(localGoodProfile?.liquidGravity?.toFixed(2))
                      : ""
                  )}
                  error={!!getFieldError("liquidGravity")}
                  helperText={getFieldError("liquidGravity")}
                  onChange={(event) => {
                    const specificGravity = parseFloat(event.target.value);
                    onChange({
                      liquidGravity: specificGravity,
                      weight:
                        localGoodProfile.unit === GoodUnits.Liters
                          ? specificGravity * 1
                          : localGoodProfile.unit === GoodUnits.Gallons
                          ? poundsToKg(specificGravity * 8.34)
                          : localGoodProfile.weight,
                    });
                  }}
                />
              </Grid>
            ) : null}

            <Grid item sm={12}>
              <ChipTagsInput
                value={localGoodProfile?.tags || []}
                onChange={(tags) => {
                  onChange({ tags });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <GroupSelectContainer
                value={localGoodProfile?.groupIds || []}
                onChange={(groupIds) => {
                  onChange({ groupIds });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t(
              "assets:commodity.suppliersAndShippers",
              "Suppliers & Shippers"
            )}
          />
          <Stack spacing={3}>
            <BusinessEntitySelectContainer
              businessEntityType={BusinessEntityType.Supplier}
              multiple={true}
              value={localGoodProfile?.supplierIds || []}
              onChange={(suppliers) => {
                onChange({ supplierIds: suppliers });
              }}
            />
            <BusinessEntitySelectContainer
              businessEntityType={BusinessEntityType.Shipper}
              multiple={true}
              value={localGoodProfile?.shipperIds || []}
              onChange={(shippers) => {
                onChange({ shipperIds: shippers });
              }}
            />
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:commodity.equivalences", "Equivalences")}
          />
          <ErrorMessage message={getFieldError("equivalences", true)} />
          <Stack spacing={2}>
            {localGoodProfile?.equivalences?.map(
              (equivalence, equivalenceIndex) => (
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      onChange({
                        equivalences: localGoodProfile?.equivalences?.filter(
                          (e, index) => index !== equivalenceIndex
                        ),
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <GoodProfileEquivalenceForm
                    key={equivalence.equivalentGoodId + "-" + equivalenceIndex}
                    equivalence={equivalence}
                    onChange={(equivalence) => {
                      onChange({
                        equivalences: localGoodProfile?.equivalences?.map(
                          (e, index) =>
                            index === equivalenceIndex ? equivalence : e
                        ),
                      });
                    }}
                    goodProfiles={goodProfiles}
                  />
                </Stack>
              )
            )}
          </Stack>
          <LabeledAddButton
            label={t("assets:commodity.addEquivalence", "Add Equivalence")}
            id="addEquivalenceButton"
            onClick={() => {
              onChange({
                equivalences: (localGoodProfile?.equivalences || []).concat({
                  equivalentGoodId: "",
                  conditions: [
                    {
                      target: GoodProfileEquivalenceConditionTarget.Date,
                      operator:
                        GoodProfileEquivalenceConditionOperator.IsGreaterThanOrEqual,
                      value: new Date(),
                    },
                  ],
                }),
              });
            }}
          />
        </Grid>

        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:commodity.restrictions", "Restrictions")}
          />
          <ErrorMessage message={getFieldError("restrictions", true)} />
          <Stack spacing={2}>
            {localGoodProfile?.restrictions?.map(
              (restriction, restrictionIndex) => (
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      onChange({
                        restrictions: localGoodProfile?.restrictions?.filter(
                          (e, index) => index !== restrictionIndex
                        ),
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <GoodProfileRestrictionForm
                    key={restriction.type + "-" + restrictionIndex}
                    restriction={restriction}
                    onChange={(restriction) => {
                      onChange({
                        restrictions: localGoodProfile?.restrictions?.map(
                          (e, index) =>
                            index === restrictionIndex ? restriction : e
                        ),
                      });
                    }}
                  />
                </Stack>
              )
            )}
          </Stack>
          <LabeledAddButton
            label={t("assets:commodity.addRestriction", "Add Restriction")}
            id="addRestrictionButton"
            onClick={() => {
              onChange({
                restrictions: (localGoodProfile?.restrictions || []).concat({
                  type: GoodProfileRestrictionType.TrailerWash,
                  value: null,
                }),
              });
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <FormDivider variant="fullWidth" text={t("common:customFields")} />

          <CustomFieldsFormContainer
            context={CustomFieldContext.Commodity}
            customFields={localGoodProfile?.customFields || []}
            onChange={(customFields) => {
              onChange({ customFields });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDivider
            variant="fullWidth"
            text={t("common:documents.many")}
            sx={{
              textTransform: "capitalize",
            }}
          />

          {localGoodProfile?.documents?.map((document) => {
            return (
              <Stack direction="row" alignItems="flex-start">
                <Box flex={1}>
                  <Link href={document.url} component="a" target="_blank">
                    {document.name}
                  </Link>
                </Box>

                <IconButton
                  onClick={() =>
                    onChange({
                      documents: without(localGoodProfile.documents, document),
                    })
                  }
                >
                  <RemoveCircle />
                </IconButton>
              </Stack>
            );
          })}

          <LabeledAddButton
            label={t("common:documents.add", "Add Document")}
            onClick={() => setDocumentUploadModalOpen(true)}
          />

          <DocumentsUploadModalContainer
            isOpen={documentUploadModalOpen}
            onSubmit={(documents) => {
              onChange({
                documents: (localGoodProfile?.documents || []).concat(
                  documents
                ),
              });
              setDocumentUploadModalOpen(false);
            }}
            onCancel={() => setDocumentUploadModalOpen(false)}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (validate()) {
              cancelConfirm();
              onSave(localGoodProfile as NewGoodProfileInput);
            }
          }}
          id="saveGoodProfileButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default GoodProfileForm;
