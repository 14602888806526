import { Box, Button, Chip, Stack, Tab, Tabs } from "@mui/material";
import {
  TripSortCriteria,
  BusinessEntityType,
  GetBusinessEntityListQuery,
  ExtensionOperationContext,
  NewBusinessEntityInputWithType,
  CustomFieldContext,
  AssetStatus,
} from "../../../graphql/generated";
import enumLabel from "../../../utils/labels/enumLabel";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import { EnumTableField, TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import locationsMapping from "../../../utils/mapping/locations";
import businessEntitySchema from "../BusinessEntityForm/businessEntitySchema";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import StorageFacilityLevelBar from "../../forecasting/ForecastingDashboard/StorageFacilityLevelBar";
import { minBy } from "lodash";

const activeBusinessEntityTypes = [
  BusinessEntityType.Shipper,
  BusinessEntityType.Receiver,
  BusinessEntityType.GeneralLocation,
  BusinessEntityType.Port,
  BusinessEntityType.TruckStop,
  BusinessEntityType.Terminal,
  BusinessEntityType.Domicile,
];

type BusinessLocationListItem =
  GetBusinessEntityListQuery["businessEntities"]["data"][0];

export default function BusinessLocationsList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<
    BusinessLocationListItem,
    "businessEntities",
    TripSortCriteria
  >;
  onBulkCreate: (locations: NewBusinessEntityInputWithType[]) => Promise<any>;
}) {
  const [locationTypeTab, setLocationTypeTab] =
    useState<BusinessEntityType | null>(null);
  const { t } = useTranslation(["business", "common", "orders"]);

  const statusField: EnumTableField<BusinessLocationListItem, AssetStatus> =
    useMemo(
      () => ({
        value: (location) => location.status,
        label: t("assets:status", "Status"),
        valueLabel: (status) => t(`common:statusTypes.${status}`),
        type: "enum",
        colors: {
          ACTIVE: "primary",
          INACTIVE: "default",
        },
        values: Object.values(AssetStatus),
        sortBy: "status",
      }),
      [t]
    );

  const fields: TableField<BusinessLocationListItem, AssetStatus>[] = useMemo(
    () => [
      {
        value: "code",
        label: t("business:code", "Code"),
        type: "string",
      },
      {
        value: "name",
        label: t("business:name", "Name"),
        type: "string",
      },
      statusField,
      {
        value: (businessLocation) =>
          [businessLocation.type, ...(businessLocation.additionalTypes || [])]
            .map((entity) =>
              // @ts-expect-error
              t(`businessEntityType.${entity}`, enumLabel(entity))
            )
            .join(", "),
        label: t("business:type", "Type"),
        type: "select",
        values: activeBusinessEntityTypes,
        sortBy: "type",
      },
      {
        value: (businessLocation) => businessLocation.address?.label,
        label: t("business:address", "Address"),
        type: "string",
        sortBy: "address.label",
      },
      {
        value: (businessLocation) =>
          formatPhoneNumber(businessLocation.contact.phoneNumber),
        subtitle: (businessLocation) => businessLocation.contact.email,
        label: t("business:contact", "Contact"),
        type: "string",
        sortBy: "contact.phoneNumber",
      },
      {
        value: (location) => (
          <Stack direction="row" spacing={1}>
            {location.tags?.map((tag) => (
              <Chip label={tag} />
            ))}
          </Stack>
        ),
        sortBy: "tags",
        label: t("common:tags"),
        type: "string",
      },
      {
        value: (location) => {
          const earliestRunoutFacility = minBy(
            location.storageFacilities?.filter((sf) => sf.latestReading),
            (facility) => facility.latestReading?.level
          );
          return (
            <Stack>
              {earliestRunoutFacility ? (
                <StorageFacilityLevelBar
                  storageFacility={earliestRunoutFacility}
                />
              ) : null}
            </Stack>
          );
        },
        label: t("common:storageFacilities", "Lowest tank"),
        type: "custom",
      },
    ],
    [t, statusField]
  );

  const getCustomFieldsContextOfCurrentTab = (
    value: BusinessEntityType | null
  ) => {
    const customFieldContextMapping: Partial<
      Record<BusinessEntityType | "all", CustomFieldContext[]>
    > = {
      all: [CustomFieldContext.Location],
      [BusinessEntityType.Shipper]: [
        CustomFieldContext.Location,
        CustomFieldContext.Shipper,
      ],
      [BusinessEntityType.Receiver]: [
        CustomFieldContext.Location,
        CustomFieldContext.Receiver,
      ],
      [BusinessEntityType.GeneralLocation]: [CustomFieldContext.Location],
      [BusinessEntityType.Port]: [CustomFieldContext.Location],
      [BusinessEntityType.TruckStop]: [CustomFieldContext.Location],
      [BusinessEntityType.Terminal]: [CustomFieldContext.Location],
      [BusinessEntityType.Domicile]: [
        CustomFieldContext.Location,
        CustomFieldContext.Domicile,
      ],
    };
    return customFieldContextMapping[value || "all"];
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <RoleGuard roles={["Carrier Admin", "Manager", "Clerical"]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <Tabs
            value={locationTypeTab}
            onChange={(event, value) => {
              setLocationTypeTab(value);
            }}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label={t("orders:filter.all", "All")}
              value={null}
              key={"all"}
            />
            {activeBusinessEntityTypes.map((activeBusinessEntityType) => (
              <Tab
                key={activeBusinessEntityType}
                label={t(
                  // @ts-expect-error
                  `businessEntityType.${activeBusinessEntityType}`,
                  enumLabel(activeBusinessEntityType)
                )}
                value={activeBusinessEntityType}
              />
            ))}
          </Tabs>
        </Box>
      </RoleGuard>
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="businessEntities"
          additionalQueryVariables={{
            businessEntityTypes:
              locationTypeTab === null
                ? activeBusinessEntityTypes
                : [locationTypeTab],
          }}
          fields={fields}
          detailsUrlPrefix="/business-locations/details"
          extensionOperationContext={ExtensionOperationContext.BusinessLocation}
          customFieldContext={getCustomFieldsContextOfCurrentTab(
            locationTypeTab
          )}
          id="business-locations-list"
          selectable
          showGroups
          renderHeaderActions={() => (
            <Stack direction="row" spacing={2}>
              <ExcelImporter
                mapping={locationsMapping}
                schema={businessEntitySchema}
                onBulkCreate={onBulkCreate}
              />
              <Button
                href="/business-locations/new"
                variant="contained"
                color="secondary"
                size="large"
                id="new-businessLocation-button"
              >
                {t("business:location.new", "New Location")}
              </Button>
            </Stack>
          )}
        />
      </Box>
    </div>
  );
}
