import {
  DeleteOutlineOutlined,
  ModeEditOutlineOutlined,
  Tag,
  Abc,
  Scale,
  Style,
  GroupWork,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GetGoodProfileDetailsQuery,
  GetGoodProfileListQuery,
  GoodUnits,
} from "../../../graphql/generated";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";

import enumLabel from "../../../utils/labels/enumLabel";
import { castArray } from "lodash";
import { formatWeightForUnit } from "../../../utils/conversion/weight";
import { useTranslation } from "react-i18next";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import GroupsDisplayContainer from "../../asset-management/GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import RoleGuard from "../../account/Access/RoleGuard";
import BusinessEntityList from "../../asset-management/BusinessEntityList";
type GoodProfileDetailsData = GetGoodProfileDetailsQuery["goodProfileById"];

type GoodProfileDetailsProps = {
  goodProfile: GoodProfileDetailsData;
  onEdit: () => void;
  onDelete: () => void;
  goodProfiles: GetGoodProfileListQuery["goodProfiles"]["data"];
};

function GoodProfileDetails({
  goodProfile,
  onEdit,
  onDelete,
  goodProfiles,
}: GoodProfileDetailsProps) {
  const { t } = useTranslation(["assets", "common", "business"]);
  return (
    <Box>
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <RoleGuard roles={["Carrier Admin"]}>
          <IconButton onClick={onDelete} color="error">
            <DeleteOutlineOutlined />
          </IconButton>
        </RoleGuard>
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <InfoBlock
            title={t(
              "assets:commodity.profileDetails",
              "Commodity Profile Details"
            )}
          >
            <LabeledInfo
              title={t("assets:commodity.code", "Commodity Code")}
              value={goodProfile.code}
              icon={<Abc />}
            />
            <LabeledInfo
              title={t("assets:commodity.label", "Label")}
              value={goodProfile.label?.toString()}
              icon={<Tag />}
            />
            <LabeledInfo
              title={t("assets:commodity.weight", "Weight")}
              value={`${formatWeightForUnit(
                goodProfile.weight,
                goodProfile.unit
              )} ${
                goodProfile.unit === GoodUnits.Gallons
                  ? "lb/gal"
                  : goodProfile.unit === GoodUnits.Liters
                  ? "kg/l"
                  : enumLabel(goodProfile.unit)
              }`}
              icon={<Scale />}
            />
            {[GoodUnits.Gallons, GoodUnits.Liters].includes(
              goodProfile.unit
            ) ? (
              <LabeledInfo
                title={t("assets:commodity.gravity", "Gravity")}
                value={`${goodProfile.liquidGravity}`}
                icon={<Scale />}
              />
            ) : null}
            <LabeledInfo
              title={t("common:tags")}
              value={<ChipTagsList value={goodProfile.tags || []} />}
              icon={<Style />}
            />
            <LabeledInfo
              title={t("common:groups")}
              value={<GroupsDisplayContainer groupIds={goodProfile.groupIds} />}
              icon={<GroupWork />}
            />
            <Grid item sm={12}>
              <LabeledInfo
                title={t("business:shipper.many")}
                value={
                  <BusinessEntityList entityIds={goodProfile.shipperIds} />
                }
              />
              <LabeledInfo
                title={t("business:supplier.many")}
                value={
                  <BusinessEntityList entityIds={goodProfile.supplierIds} />
                }
              />
            </Grid>
          </InfoBlock>
        </Grid>
        <Grid item sm={12}>
          <InfoBlock title={t("assets:commodity.equivalences", "Equivalences")}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(
                      "assets:commodity.equivalentCommodity",
                      "Equivalent commodity"
                    )}
                  </TableCell>
                  <TableCell>Conditions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goodProfile.equivalences?.map((equivalence) => (
                  <TableRow>
                    <TableCell>
                      {goodProfiles.find(
                        (goodProfile) =>
                          goodProfile._id === equivalence.equivalentGoodId
                      )?.label || "N/A"}
                    </TableCell>
                    <TableCell>
                      {equivalence.conditions.length} conditions
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>
        <Grid item sm={12}>
          <InfoBlock title="Restrictions">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("assets:commodity.restrictionType", "Restriction type")}
                  </TableCell>
                  <TableCell>{t("assets:commodity.value", "Value")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goodProfile.restrictions?.map((restriction) => (
                  <TableRow>
                    <TableCell>{enumLabel(restriction.type)}</TableCell>
                    <TableCell>
                      {castArray(restriction.value).map(enumLabel).join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>
        <Grid item xs={12}>
          <CustomFieldsDisplayContainer
            customFields={goodProfile.customFields}
          />
        </Grid>
        <Grid item sm={12}>
          <InfoBlock title={t("common:documents.many")}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("common:name")}</TableCell>
                  <TableCell
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {t("common:documents.one")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goodProfile.documents?.map((document) => (
                  <TableRow>
                    <TableCell>{document.name}</TableCell>
                    <TableCell>
                      <Link href={document.url} component="a" target="_blank">
                        Open
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GoodProfileDetails;
