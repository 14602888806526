import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {
  ForecastMutation,
  ForecastingPayload,
  ForecastingUptickInput,
  GetReceiverForecastDetailsQuery,
} from "../../../graphql/generated";
import { LoadingButton } from "@mui/lab";
import ShipmentsList from "../../shipment/ShipmentsList/ShipmentsList";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { AutoGraph, Save } from "@mui/icons-material";
import { addDays, addHours, startOfHour } from "date-fns";
import UptickForm from "./UptickForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import ShipmentGeneralInfos from "../../shipment/ShipmentDetails/ShipmentGeneralInfos";

export type ForecastResultShipment =
  ForecastMutation["forecast"]["shipments"][0];

export type ForecastSimulatorProps = {
  receiverForecast: GetReceiverForecastDetailsQuery["receiverForecastById"];
  onSimulate: (payload: ForecastingPayload) => void;
  shipments: ForecastResultShipment[];
  simulating: boolean;
  saving: boolean;
  onSave: (shipments: ForecastResultShipment[]) => void;
};

const ForecastSimulator = (props: ForecastSimulatorProps) => {
  const [startDate, setStartDate] = useState<Date>(
    startOfHour(addHours(new Date(), 1))
  );
  const [endDate, setEndDate] = useState<Date | null>(addDays(startDate, 1));
  const [upticks, setUpticks] = useState<ForecastingUptickInput[]>([]);
  const [uptickModalOpen, setUptickModalOpen] = useState(false);
  const [shipmentDetailsModalOpen, setShipmentDetailsModalOpen] =
    useState(false);
  const [selectedShipment, setSelectedShipment] =
    useState<ForecastResultShipment | null>(null);

  return (
    <Stack spacing={2}>
      <DateTimePicker
        label="Start Date"
        value={startDate}
        onChange={(date) => {
          if (!date) {
            return;
          }
          setStartDate(date);
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
        maxDate={endDate}
        minutesStep={15}
        ampm={false}
      />
      <DateTimePicker
        label="End Date"
        value={endDate}
        onChange={(date) => setEndDate(date)}
        renderInput={(params) => <TextField {...params} fullWidth />}
        minDate={startDate}
        minutesStep={15}
        ampm={false}
      />
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() => {
            setUptickModalOpen(true);
          }}
        >
          Uptick
        </Button>
        <LoadingButton
          onClick={() => {
            props.onSimulate({
              startDate,
              endDate,
              receiverForecastId: props.receiverForecast._id,
              upticks,
            });
          }}
          loading={props.simulating}
          startIcon={<AutoGraph />}
          variant="contained"
          sx={{ alignSelf: "center" }}
        >
          Simulate
        </LoadingButton>
      </Stack>

      <ShipmentsList
        query={() => ({
          data: {
            shipments: {
              data: props.shipments,
              count: props.shipments.length,
            },
          },
          isLoading: props.simulating,
        })}
        disablePagination
        disableSearch
        disableStatusTab
        readonly
        onRecordClick={(shipment) => {
          const forecastedShipment = props.shipments.find(
            (s) => s._id === shipment._id
          );
          if (forecastedShipment) {
            setSelectedShipment(forecastedShipment);
            setShipmentDetailsModalOpen(true);
          }
        }}
      />

      <LoadingButton
        onClick={() => {
          props.onSave(props.shipments);
        }}
        loading={props.saving}
        variant="contained"
        sx={{ alignSelf: "center" }}
        startIcon={<Save />}
      >
        Save Orders
      </LoadingButton>

      <Dialog
        open={uptickModalOpen}
        onClose={() => {
          setUptickModalOpen(false);
        }}
      >
        <DialogTitle>Uptick</DialogTitle>
        <DialogContent>
          {upticks.map((uptick) => (
            <UptickForm
              receiverId={props.receiverForecast.receiverId}
              uptick={uptick}
              onChange={(newUptick) => {
                setUpticks(upticks.map((u) => (u === uptick ? newUptick : u)));
              }}
            />
          ))}
          <LabeledAddButton
            label="Add Uptick"
            onClick={() => {
              setUpticks([
                ...upticks,
                { storageFacilityId: "", multiplier: 1 },
              ]);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={shipmentDetailsModalOpen}
        onClose={() => {
          setShipmentDetailsModalOpen(false);
        }}
      >
        <DialogTitle>Shipment Details</DialogTitle>
        <DialogContent>
          {selectedShipment && (
            <ShipmentGeneralInfos shipment={selectedShipment} />
          )}
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default ForecastSimulator;
