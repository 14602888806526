import { Alert } from "@mui/material";
import { capitalize, omit, toArray } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BusinessEntity,
  BusinessEntityType,
  NewBusinessEntityInputWithType,
  useAddBusinessEntityMutation,
  useEditBusinessEntityMutation,
  useGetBusinessEntityDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import BusinessEntityForm, {
  BusinessEntityFormProps,
} from "./BusinessEntityForm";
import { useTranslation } from "react-i18next";

type BusinessEntityFormContainerProps = Omit<
  BusinessEntityFormProps,
  "initialBusinessEntity" | "saving" | "onSave" | "goodProfiles"
> & {
  businessEntityId?: string;
  urlPrefix?: string;
  onLoad?: (businessEntity: NewBusinessEntityInputWithType) => void;
  navigateAfterSave?: boolean;
  onSaved?: (businessEntity: Pick<BusinessEntity, "_id">) => void;
};

function BusinessEntityFormContainer({
  businessEntityId,
  urlPrefix,
  onLoad,
  onSaved,
  navigateAfterSave = true,
  ...props
}: BusinessEntityFormContainerProps) {
  const { t } = useTranslation(["business", "common"]);
  const initialBusinessEntityId = useRef(businessEntityId);
  const addBusinessEntityMutation = useAddBusinessEntityMutation();
  const editBusinessEntityMutation = useEditBusinessEntityMutation();
  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getBusinessEntityQuery = initialBusinessEntityId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetBusinessEntityDetailsQuery(
        {
          id: initialBusinessEntityId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onLoad?.(toArray(getBusinessEntityQuery?.data)[0]);
  }, [onLoad, getBusinessEntityQuery?.data]);

  if (businessEntityId) {
    if (getBusinessEntityQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getBusinessEntityQuery?.error) {
      return (
        <Alert severity="error">
          {t(
            "error.fetchBusinessError",
            "An error occurred while fetching the business entity"
          )}
          .{" "}
          {(getBusinessEntityQuery?.error as Error).message ||
            t("common:error.unknownError", "Unknown error")}
        </Alert>
      );
    }
  }

  return (
    <BusinessEntityForm
      {...props}
      initialBusinessEntity={toArray(getBusinessEntityQuery?.data)[0]}
      saving={
        addBusinessEntityMutation.isLoading ||
        editBusinessEntityMutation.isLoading
      }
      onSave={async (businessEntity) => {
        try {
          if (businessEntityId) {
            const businessEntityData = omit(
              {
                ...businessEntity,
                storageFacilities: businessEntity?.storageFacilities?.map(
                  (storageFacility) =>
                    omit(
                      storageFacility,
                      "commodity",
                      "defaultSupplier",
                      "latestReading"
                    )
                ),
              },
              "_id",
              "addressTimezone",
              "defaultShipper"
            );
            if (businessEntity.type === BusinessEntityType.PayToProfile) {
              // Put an N/A address for pay to profiles
              // This is because the API requires an address for all business
              // entities but pay to profiles are just virtual entities
              businessEntityData.address = {
                label: "N/A",
                coordinates: {
                  latitude: 0,
                  longitude: 0,
                },
              };
            }
            const result = await editBusinessEntityMutation.mutateAsync({
              id: businessEntityId,
              editBusinessEntityData: businessEntityData,
            });
            onSaved?.(result.editBusinessEntity);
            if (navigateAfterSave && urlPrefix) {
              navigate(`${urlPrefix}/details/${result.editBusinessEntity._id}`);
            }
            window.analytics?.track("Business Entity Edited", {
              businessEntityType: businessEntity.type,
              businessEntityId,
            });
          } else {
            const businessEntityData = omit(
              businessEntity,
              "_id",
              "addressTimezone"
            );
            if (businessEntity.type === BusinessEntityType.PayToProfile) {
              // Put an N/A address for pay to profiles
              // This is because the API requires an address for all business
              // entities but pay to profiles are just virtual entities
              businessEntityData.address = {
                label: "N/A",
                coordinates: {
                  latitude: 0,
                  longitude: 0,
                },
              };
            }
            const result = await addBusinessEntityMutation.mutateAsync({
              newBusinessEntityData: businessEntityData,
            });
            onSaved?.(result.addBusinessEntity);
            if (navigateAfterSave && urlPrefix) {
              navigate(`${urlPrefix}/details/${result.addBusinessEntity._id}`);
            }
            window.analytics?.track("Business Entity Created", {
              businessEntityType: businessEntity.type,
              businessEntityId: result.addBusinessEntity._id,
            });

            window.analytics?.identify({
              [`${businessEntity.type.toLowerCase()}Created`]: true,
              [`last${capitalize(businessEntity.type)}CreationDate`]:
                new Date(),
              [`last${capitalize(businessEntity.type)}CreationDateOnly`]:
                new Date().toISOString().split("T")[0],
              [`numberOf${capitalize(businessEntity.type)}sCreated`]:
                // @ts-ignore
                (window.analytics?.user?.()?.traits?.()?.[
                  `numberOf${capitalize(businessEntity.type)}sCreated`
                ] || 0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              [`${businessEntity.type.toLowerCase()}Created`]: true,
              [`last${capitalize(businessEntity.type)}CreationDate`]:
                new Date(),
              [`last${capitalize(businessEntity.type)}CreationDateOnly`]:
                new Date().toISOString().split("T")[0],
              [`numberOf${capitalize(businessEntity.type)}sCreated`]:
                // @ts-ignore
                (window.analytics?.group?.()?.traits?.()?.[
                  `numberOf${capitalize(businessEntity.type)}sCreated`
                ] || 0) + 1,
            });

            const traits = window.analytics?.user?.()?.traits?.();
            window.analytics?.identify({
              rolodexPopulated:
                (traits?.customerCreated || traits?.brokerCreated) &&
                traits?.shipperCreated &&
                traits?.receiverCreated,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              rolodexPopulated:
                (traits?.customerCreated || traits?.brokerCreated) &&
                traits?.shipperCreated &&
                traits?.receiverCreated,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: t("common:error.title", "Error"),
              description:
                t("error.createEditBusinessError", {
                  action: businessEntityId
                    ? t("common:editing", "editing")
                    : t("common:creating", "creating"),
                  defaulValue: `An error occurred while ${
                    businessEntityId ? "editing" : "creating"
                  } the business entity. `,
                }) + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      {...props}
    />
  );
}

export default BusinessEntityFormContainer;
