import { BusinessEntity } from "../../graphql/generated";

function businessEntityLabel(
  businessEntity: Pick<BusinessEntity, "name" | "code"> | null | undefined,
  includeCode: boolean = false
) {
  if (!businessEntity) {
    return "N/A";
  }
  return (
    `${includeCode ? `${businessEntity.code} / ` : ""}${
      businessEntity.name || ""
    }`.trim() || "N/A"
  );
}

export default businessEntityLabel;
