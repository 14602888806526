import { Grid, Stack, Typography } from "@mui/material";
import BusinessEntityLinkContainer from "./BusinessEntityLink";

export default function BusinessEntityList({
  inline,
  entityIds,
}: {
  inline?: boolean;
  entityIds?: string[] | null;
}) {
  return inline ? (
    entityIds?.length ? (
      <Stack direction="row" spacing={1}>
        {entityIds.map((entityId) => (
          <BusinessEntityLinkContainer entityId={entityId} />
        ))}
      </Stack>
    ) : (
      <Typography variant="body2">N/A</Typography>
    )
  ) : (
    <Grid container direction="row" spacing={1}>
      {entityIds?.length ? (
        entityIds.map((entityId) => (
          <Grid item>
            <BusinessEntityLinkContainer entityId={entityId} />
          </Grid>
        ))
      ) : (
        <Typography variant="body2">N/A</Typography>
      )}
    </Grid>
  );
}
