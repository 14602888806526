import { Typography } from "@mui/material";
import { Scalars, useGetGroupsQuery } from "../../../graphql/generated";
import GroupsDisplay from "./GroupsDisplay";

type GroupsDisplayContainerProps = {
  groupIds?: Scalars["ObjectId"][] | null;
};

const GroupsDisplayContainer = ({ groupIds }: GroupsDisplayContainerProps) => {
  const { data } = useGetGroupsQuery({});

  const groups =
    data?.groups.data.filter((group) => groupIds?.includes(group._id)) || [];

  return groups.length > 0 ? (
    <GroupsDisplay groups={groups} />
  ) : (
    <Typography variant="body2">N/A</Typography>
  );
};

export default GroupsDisplayContainer;
