import { Link } from "@mui/material";
import { BusinessEntity, BusinessEntityType } from "../../../graphql/generated";
import businessEntityLabel from "../../../utils/labels/businessEntityLabel";

export type BusinessEntityLinkProps = {
  entityDetails: Pick<BusinessEntity, "_id" | "name" | "code" | "type">;
};

const businessEntityTypeRouteMap: { [key in BusinessEntityType]: string } = {
  [BusinessEntityType.Broker]: "customers",
  [BusinessEntityType.Customer]: "customers",
  [BusinessEntityType.PayToProfile]: "payto",
  [BusinessEntityType.Carrier]: "carriers",
  [BusinessEntityType.Shipper]: "business-locations",
  [BusinessEntityType.Receiver]: "business-locations",
  [BusinessEntityType.GeneralLocation]: "business-locations",
  [BusinessEntityType.Port]: "business-locations",
  [BusinessEntityType.TruckStop]: "business-locations",
  [BusinessEntityType.Terminal]: "business-locations",
  [BusinessEntityType.Domicile]: "business-locations",
  [BusinessEntityType.Supplier]: "suppliers",
};

const BusinessEntityLink: React.FC<BusinessEntityLinkProps> = ({
  entityDetails,
}) => {
  return (
    <Link
      href={`/${businessEntityTypeRouteMap[entityDetails.type]}/details/${
        entityDetails?._id
      }`}
      target="_blank"
      component="a"
    >
      {businessEntityLabel(entityDetails)}
    </Link>
  );
};

export default BusinessEntityLink;
