import Joi from "joi";
import { Relationship } from "../../../graphql/generated";

const driverShiftSchema = Joi.object<Relationship>({
  supplierIds: Joi.array().items(Joi.string()).label("Supplier"),
  shipperIds: Joi.array().items(Joi.string()).label("Shipper"),
  commodityIds: Joi.array().items(Joi.string()).label("Commodity"),
  receiverIds: Joi.array().items(Joi.string()).label("Receiver"),
  customerIds: Joi.array().items(Joi.string()).label("Customer"),
});

export default driverShiftSchema;
