import { useTranslation } from "react-i18next";
import { GetInvoiceDetailsQuery, PaymentMethod } from "../../graphql/generated";
import { useEffect, useState } from "react";
import ObjectID from "bson-objectid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import InvoicePaymentForm, {
  InvoicePaymentData,
} from "../accounting/InvoicePaymentForm/InvoicePaymentForm";

type InvoicePaymentFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialPayment?: InvoicePaymentData | null;
  onSubmit: (payment: InvoicePaymentData) => void;
};

const defaultInitialPayment: NonNullable<
  GetInvoiceDetailsQuery["invoiceById"]["payments"]
>[0] = {
  _id: new ObjectID().toHexString(),
  date: new Date().toISOString(),
  amount: 0,
  note: "",
  paymentMethod: PaymentMethod.Cash,
};

export default function InvoicePaymentFormModal({
  isOpen,
  initialPayment,
  onClose,
  onSubmit,
}: InvoicePaymentFormModalProps) {
  const { t } = useTranslation(["finance", "common"]);
  const [payment, setPayment] = useState<InvoicePaymentData>(
    initialPayment || {
      ...defaultInitialPayment,
    }
  );

  useEffect(() => {
    if (initialPayment) {
      setPayment(initialPayment);
    } else {
      setPayment({
        ...defaultInitialPayment,
      });
    }
  }, [initialPayment]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({
      ...payment,
      _id: payment._id || new ObjectID().toHexString(),
    });
    setPayment({
      ...defaultInitialPayment,
    });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen}>
      <DialogTitle variant="h6">
        {t("finance:addPayment", "Add Payment")}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <InvoicePaymentForm payment={payment} onChange={setPayment} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={handleCancel}
        >
          {t("common:cancel", "Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          id="validate-payment-button"
        >
          {t("common:validate", "Validate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
