import { useGetBusinessEntityDetailsQuery } from "../../../graphql/generated";
import BusinessEntityLink from "./BusinessEntityLink";

type BusinessEntityLinkContainerProps = {
  entityId: string;
};

const BusinessEntityLinkContainer: React.FC<BusinessEntityLinkContainerProps> =
  ({ entityId }) => {
    const businessEntityDetailsQuery = useGetBusinessEntityDetailsQuery({
      id: entityId,
    });

    if (!businessEntityDetailsQuery.data?.businessEntityById._id) {
      return null;
    }

    return (
      <BusinessEntityLink
        entityDetails={businessEntityDetailsQuery.data.businessEntityById}
      />
    );
  };

export default BusinessEntityLinkContainer;
