import { SelectProps } from "@mui/material";
import { ConditionValueSelectorComponentProps } from "../ConditionForm";
import StateSelect from "../../../common/StateSelect";
import { castArray } from "lodash";

const ConditionStateSelect = ({
  value,
  onChange,
  ...otherProps
}: ConditionValueSelectorComponentProps & Pick<SelectProps<string>, "sx">) => {
  return (
    <StateSelect
      label={otherProps.label}
      value={castArray(value) || null}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      SelectProps={{
        native: false,
        multiple: true,
      }}
      {...otherProps}
    />
  );
};

export default ConditionStateSelect;
