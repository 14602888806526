import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { FormDivider } from "../BusinessEntityForm/BusinessEntityForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewTrailerInput,
  TrailerType,
  AssetStatus,
  TrailerCompartmentUnit,
  CustomFieldContext,
  GetTrailersQuery,
} from "../../../graphql/generated";
import AddressInput from "../AddressInput";
import { identity, isArray, mergeWith, omit, sortBy, without } from "lodash";
import trailerSchema from "./trailerSchema";
import LocaleProvider from "../../../providers/LocaleProvider";
import formatWeight from "../../../utils/labels/formatWeight";
import { localWeightToKg } from "../../../utils/conversion/weight";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import { localTemperatureToCelsius } from "../../../utils/conversion/temperature";
import formatTemperature from "../../../utils/labels/formatTemperature";
import defaultCapacityByTrailerType, {
  defaultCapacity,
} from "./defaultCapacityByTrailerType";
import { hasNumberValue, hasValue } from "../../../utils/form/hasValue";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import DistanceInput from "../../common/DistanceInput";
import { Add, Download, RemoveCircle } from "@mui/icons-material";
import AddButton from "../../common/AddButton";
import FeatureGuard, { Feature } from "../../account/Access/FeatureGuard";
import { useTranslation } from "react-i18next";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../GroupSelect";
import MileageInput from "../../accounting/BillingRuleForm/value-selectors/MileageInput";
import TrailerSelectContainer from "../TrailerSelect";
import { useSnackbar } from "notistack";
import LabeledAddButton from "../../common/LabeledAddButton";
import DocumentsUploadModalContainer from "../../common/DocumentsUploadModal";

type TrailerFormProps = {
  initialTrailer?: NewTrailerInput;
  saving: boolean;
  onSave: (trailer: NewTrailerInput) => void;
  ownedTrailerTypes: TrailerType[];
};
type PartialTrailer = Partial<NewTrailerInput>;
type DeepPartialTrailer = {
  [key in keyof PartialTrailer]: Partial<PartialTrailer[key]>;
};

const TrailerForm = ({
  initialTrailer,
  saving,
  onSave,
  ownedTrailerTypes,
}: TrailerFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["assets", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewTrailerInput> | null>(null);
  const [localTrailer, setLocalTrailer] = useState<Partial<NewTrailerInput>>(
    initialTrailer || {}
  );
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const [activeTrailerToImport, setActiveTrailerToImport] =
    useState<GetTrailersQuery["trailers"]["data"][0] | null | undefined>(null);

  const notOwnedTrailerTypes = without(
    Object.values(TrailerType),
    ...ownedTrailerTypes
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialTrailer) => {
    setLocalTrailer((localTrailer) =>
      mergeWith({}, localTrailer, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = trailerSchema.validate(omit(localTrailer, "_id"), {
      abortEarly: false,
    });
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTrailer]);

  const { cancelConfirm } = useConfirmBeforeLeave(localTrailer);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:trailer.details", "Trailer details")}
          />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("assets:code", "Asset Code")}
                name="assetCode"
                required
                fullWidth
                value={localTrailer?.serialNumber || ""}
                error={!!getFieldError("serialNumber")}
                helperText={getFieldError("serialNumber")}
                onChange={(event) => {
                  onChange({ serialNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:licenseNumber", "License Number")}
                name="licenseNumber"
                fullWidth
                value={localTrailer?.licenseNumber || ""}
                error={!!getFieldError("licenseNumber")}
                helperText={getFieldError("licenseNumber")}
                onChange={(event) => {
                  onChange({ licenseNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:vin", "VIN#")}
                name="vin"
                fullWidth
                value={localTrailer?.vin || ""}
                error={!!getFieldError("vin")}
                helperText={getFieldError("vin")}
                onChange={(event) => {
                  onChange({ vin: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:serial", "Serial Number")}
                name="serial"
                fullWidth
                value={localTrailer?.serial || ""}
                error={!!getFieldError("serial")}
                helperText={getFieldError("serial")}
                onChange={(event) => {
                  onChange({ serial: event.target.value });
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                required
                fullWidth
                label={t("assets:trailer.type", "Trailer Type")}
                name="trailerType"
                size="small"
                select
                value={localTrailer?.type}
                error={!!getFieldError("type")}
                helperText={getFieldError("type")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    type: event.target.value as TrailerType,
                    ...(defaultCapacityByTrailerType[
                      event.target.value as TrailerType
                    ] || defaultCapacity),
                  });
                }}
              >
                <ListSubheader>
                  {t("assets:trailer.owned", "Owned")}
                </ListSubheader>
                {sortBy(ownedTrailerTypes, identity).map((type) => (
                  <MenuItem key={type} value={type as TrailerType}>
                    {trailerTypeLabel(type)}
                  </MenuItem>
                ))}
                <ListSubheader>
                  {t("assets:trailer.others", "Others")}
                </ListSubheader>
                {sortBy(notOwnedTrailerTypes, identity).map((type) => (
                  <MenuItem key={type} value={type as TrailerType}>
                    {trailerTypeLabel(type)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={12}>
              <MileageInput
                value={localTrailer?.mileage ? localTrailer.mileage : ""}
                onChange={(value) => {
                  onChange({
                    mileage: Number(value),
                  });
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <EnumSelect
                label={t("assets:status", "Status")}
                fullWidth
                enumObject={AssetStatus}
                optionLabel={(status) => t(`common:statusTypes.${status}`)}
                error={!!getFieldError("status")}
                helperText={getFieldError("status")}
                value={localTrailer?.status || AssetStatus.Active}
                onChange={(event, status) => {
                  if (!status) {
                    return;
                  }
                  onChange({
                    status,
                  });
                }}
                name="status"
              />
            </Grid>
            <Grid item sm={12}>
              <ChipTagsInput
                value={localTrailer?.tags || []}
                onChange={(tags) => {
                  onChange({ tags });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <GroupSelectContainer
                value={localTrailer?.groupIds || []}
                onChange={(groupIds) => {
                  onChange({ groupIds });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:domicile", "Domicile")}
          />
          <ErrorMessage message={getFieldError("address")} />

          <AddressInput
            value={localTrailer?.domicile || null}
            onChange={(domicile) => {
              onChange({ domicile });
            }}
            errors={{
              label: getFieldError("domicile.label"),
              coordinates: getFieldError("domicile.coordinates"),
              line1: getFieldError("domicile.line1"),
              line2: getFieldError("domicile.line2"),
              postalCode: getFieldError("domicile.postalCode"),
              state: getFieldError("domicile.state"),
              city: getFieldError("domicile.city"),
              country: getFieldError("domicile.country"),
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:trailer.capacity", "Trailer Capacity")}
          />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("assets:trailer.maxWeightWithUnit", {
                  unit: LocaleProvider.getWeightUnit(),
                  defaultValue: `Max Weight (${LocaleProvider.getWeightUnit()})`,
                })}
                name="maxWeight"
                fullWidth
                value={
                  hasValue(localTrailer?.maxWeight)
                    ? formatWeight(Number(localTrailer?.maxWeight), false)
                    : ""
                }
                error={!!getFieldError("maxWeight")}
                helperText={getFieldError("maxWeight")}
                type="number"
                onChange={(event) => {
                  onChange({
                    maxWeight: hasNumberValue(event.target.value)
                      ? localWeightToKg(Number.parseFloat(event.target.value))
                      : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <DistanceInput
                error={!!getFieldError("width")}
                helperText={getFieldError("width")}
                value={
                  hasValue(localTrailer?.width)
                    ? Number(localTrailer?.width).toString()
                    : ""
                }
                label={t("assets:trailer.widthWithUnit", {
                  unit: LocaleProvider.getDistanceUnit(),
                  defaultValue: `Width (${LocaleProvider.getDistanceUnit()})`,
                })}
                name="width"
                onChange={(e) => {
                  onChange({
                    width:
                      hasNumberValue(e.target.value) &&
                      Number(e.target.value) !== 0
                        ? Number.parseFloat(e.target.value)
                        : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <DistanceInput
                label={t("assets:trailer.heightWithUnit", {
                  unit: LocaleProvider.getDistanceUnit(),
                  defaultValue: `Height (${LocaleProvider.getDistanceUnit()})`,
                })}
                name="height"
                fullWidth
                value={
                  hasValue(localTrailer?.height)
                    ? Number(localTrailer?.height).toString()
                    : ""
                }
                error={!!getFieldError("height")}
                helperText={getFieldError("height")}
                onChange={(e) => {
                  onChange({
                    height:
                      hasNumberValue(e.target.value) &&
                      Number(e.target.value) !== 0
                        ? Number.parseFloat(e.target.value)
                        : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <DistanceInput
                label={t("assets:trailer.lengthWithUnit", {
                  unit: LocaleProvider.getDistanceUnit(),
                  defaultValue: `Length (${LocaleProvider.getDistanceUnit()})`,
                })}
                name="length"
                fullWidth
                value={
                  hasValue(localTrailer?.length)
                    ? Number(localTrailer?.length).toString()
                    : ""
                }
                error={!!getFieldError("length")}
                helperText={getFieldError("length")}
                onChange={(e) => {
                  onChange({
                    length:
                      hasNumberValue(e.target.value) &&
                      Number(e.target.value) !== 0
                        ? Number.parseFloat(e.target.value)
                        : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:trailer.numberOfAxles", "Number of Axles")}
                name="numberOfAxles"
                fullWidth
                value={localTrailer?.numberOfAxles ?? ""}
                error={!!getFieldError("numberOfAxles")}
                helperText={getFieldError("numberOfAxles")}
                type="number"
                onChange={(event) => {
                  onChange({
                    numberOfAxles: hasNumberValue(event.target.value)
                      ? Number(event.target.value)
                      : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:trailer.minTemperatureWithUnit", {
                  unit: LocaleProvider.getTemperatureUnit(),
                  defaultValue: `Min Temperature (${LocaleProvider.getTemperatureUnit()})`,
                })}
                name="minTemperature"
                fullWidth
                value={
                  hasValue(localTrailer?.minTemperature)
                    ? formatTemperature(
                        Number(localTrailer?.minTemperature),
                        false
                      )
                    : ""
                }
                error={!!getFieldError("minTemperature")}
                helperText={getFieldError("minTemperature")}
                type="number"
                onChange={(event) => {
                  onChange({
                    minTemperature: hasNumberValue(event.target.value)
                      ? localTemperatureToCelsius(
                          parseFloat(event.target.value)
                        )
                      : null,
                  });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:trailer.maxTemperatureWithUnit", {
                  unit: LocaleProvider.getTemperatureUnit(),
                  defaultValue: `Max Temperature (${LocaleProvider.getTemperatureUnit()})`,
                })}
                name="maxTemperature"
                fullWidth
                value={
                  hasValue(localTrailer?.maxTemperature)
                    ? formatTemperature(
                        Number(localTrailer?.maxTemperature),
                        false
                      )
                    : ""
                }
                error={!!getFieldError("maxTemperature")}
                helperText={getFieldError("maxTemperature")}
                type="number"
                onChange={(event) => {
                  onChange({
                    maxTemperature: hasNumberValue(event.target.value)
                      ? localTemperatureToCelsius(
                          parseFloat(event.target.value)
                        )
                      : null,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <FormDivider variant="fullWidth" text={t("common:customFields")} />

          <CustomFieldsFormContainer
            context={CustomFieldContext.Trailer}
            customFields={localTrailer?.customFields || []}
            onChange={(customFields) => {
              onChange({ customFields });
            }}
          />
        </Grid>

        <FeatureGuard feature={Feature.CommodityManagement}>
          <Grid item sm={12}>
            <Box
              sx={{
                pt: 4,
              }}
            >
              <FormDivider
                variant="fullWidth"
                text={t("assets:trailer.compartments", "Compartments")}
              />
              <ErrorMessage message={getFieldError("compartments", true)} />
              {localTrailer?.compartments?.map((compartment) => (
                <Stack direction="row" spacing={3} sx={{ mb: 2, mt: 1 }}>
                  <TextField
                    label={t("assets:trailer.identifier", "Identifier")}
                    fullWidth
                    name="storageFacility.identifier"
                    value={compartment.identifier || ""}
                    onChange={(event) => {
                      onChange({
                        compartments: (localTrailer.compartments || []).map(
                          (c) =>
                            c === compartment
                              ? {
                                  ...c,
                                  identifier: event.target.value,
                                }
                              : c
                        ),
                      });
                    }}
                  />
                  <TextField
                    label={t("assets:trailer.capacity", "Capacity")}
                    fullWidth
                    type="number"
                    name="compartment.capacity"
                    value={String(compartment.capacity) || ""}
                    onChange={(event) => {
                      onChange({
                        compartments: (localTrailer.compartments || []).map(
                          (c) =>
                            c === compartment
                              ? {
                                  ...c,
                                  capacity: parseFloat(event.target.value),
                                }
                              : c
                        ),
                      });
                    }}
                  />
                  <EnumSelect
                    enumObject={TrailerCompartmentUnit}
                    label={t("assets:commodity.unit", "Unit")}
                    fullWidth
                    name="compartment.unit"
                    value={compartment.unit}
                    onChange={(event, value) => {
                      if (!value) {
                        return;
                      }
                      onChange({
                        compartments: (localTrailer.compartments || []).map(
                          (c) =>
                            c === compartment
                              ? {
                                  ...c,
                                  unit: value,
                                }
                              : c
                        ),
                      });
                    }}
                  />

                  <IconButton
                    onClick={() =>
                      onChange({
                        compartments: without(
                          localTrailer.compartments,
                          compartment
                        ),
                      })
                    }
                  >
                    <RemoveCircle />
                  </IconButton>
                </Stack>
              ))}
              <Stack
                direction="row"
                spacing={2}
                sx={{ m: 1, display: "flex", alignItems: "center" }}
              >
                <AddButton
                  sx={{ height: 50, width: 20 }}
                  onClick={() => {
                    onChange({
                      compartments: (localTrailer?.compartments || []).concat({
                        identifier: `Compartment #${
                          (localTrailer?.compartments || []).length + 1
                        }`,
                        capacity: 0,
                        unit: TrailerCompartmentUnit.Gallons,
                      }),
                    });
                  }}
                  id="add-compartment-button"
                >
                  <Add />
                </AddButton>
                <span>
                  {t("assets:trailer.addCompartment", "Add Compartment")}
                </span>
                <Divider orientation="vertical" flexItem />
                <span>
                  {t(
                    "assets:trailer.importCompartmentsFrom",
                    "Import compartments from"
                  )}
                </span>
                <TrailerSelectContainer
                  sx={{ width: 300 }}
                  onChange={(trailerToImport) => {
                    setActiveTrailerToImport(trailerToImport);
                  }}
                  value={activeTrailerToImport}
                  id="import-compartments-config-select"
                />
                <Button
                  disabled={!activeTrailerToImport}
                  onClick={() => {
                    if (activeTrailerToImport?.compartments?.length === 0) {
                      enqueueSnackbar(
                        t(
                          "assets:trailer.noCompartmentsToImport",
                          "No compartments to import"
                        ),
                        {
                          variant: "info",
                        }
                      );
                      setActiveTrailerToImport(null);
                      return;
                    }

                    onChange({
                      compartments: (localTrailer?.compartments || []).concat([
                        ...(activeTrailerToImport?.compartments || []).map(
                          (c, j) => ({
                            ...c,
                            identifier: `Compartment #${
                              (localTrailer?.compartments || []).length + 1 + j
                            }`,
                          })
                        ),
                      ]),
                    });
                    setActiveTrailerToImport(null);
                    enqueueSnackbar(
                      t(
                        "assets:trailer.compartmentsImported",
                        "Compartments imported"
                      ),
                      {
                        variant: "success",
                      }
                    );
                  }}
                  variant="outlined"
                  endIcon={<Download />}
                  id="import-compartments-config-button"
                >
                  {t("common:list.import", "Import")}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </FeatureGuard>

        <Grid item xs={12}>
          <FormDivider
            variant="fullWidth"
            text={t("common:documents.many")}
            sx={{
              textTransform: "capitalize",
            }}
          />

          {localTrailer?.documents?.map((document) => {
            return (
              <Stack direction="row" alignItems="flex-start">
                <Box flex={1}>
                  <Link href={document.url} component="a" target="_blank">
                    {document.name}
                  </Link>
                </Box>

                <IconButton
                  onClick={() =>
                    onChange({
                      documents: without(localTrailer.documents, document),
                    })
                  }
                >
                  <RemoveCircle />
                </IconButton>
              </Stack>
            );
          })}

          <LabeledAddButton
            label={t("common:documents.add", "Add Document")}
            onClick={() => setDocumentUploadModalOpen(true)}
          />

          <DocumentsUploadModalContainer
            isOpen={documentUploadModalOpen}
            onSubmit={(documents) => {
              onChange({
                documents: (localTrailer?.documents || []).concat(documents),
              });
              setDocumentUploadModalOpen(false);
            }}
            onCancel={() => setDocumentUploadModalOpen(false)}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (validate()) {
              cancelConfirm();
              onSave(localTrailer as NewTrailerInput);
            }
          }}
          id="saveTrailerButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default TrailerForm;
