import { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewRelationshipInput,
  GetRelationshipDetailsQuery,
  GetDriverListQuery,
  GetBusinessEntityListQuery,
  BusinessEntityType,
} from "../../../graphql/generated";
import { capitalize, isArray, mergeWith, omit } from "lodash";
import relationshipSchema from "./relationshipSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import BusinessEntitySelectContainer from "../../asset-management/BusinessEntitySelect";
import GoodProfileSelectContainer from "../../commodity-management/GoodProfileSelect";
import { useTranslation } from "react-i18next";

type Relationship = GetRelationshipDetailsQuery["relationshipById"];

type RelationshipFormProps = {
  initialRelationship?: Relationship;
  drivers: GetDriverListQuery["drivers"]["data"];
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  saving: boolean;
  onSave: (relationship: NewRelationshipInput) => void;
};
type PartialRelationship = Partial<Relationship>;
type DeepPartialRelationship = {
  [key in keyof PartialRelationship]: Partial<PartialRelationship[key]>;
};

const RelationshipForm = ({
  initialRelationship,
  saving,
  onSave,
  drivers,
  domiciles,
}: RelationshipFormProps) => {
  const { t } = useTranslation(["common", "business"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<Relationship> | null>(null);
  const [localRelationship, setLocalRelationship] =
    useState<PartialRelationship>(initialRelationship || {});

  const onChange = useCallback((changes: DeepPartialRelationship) => {
    setLocalRelationship((localRelationship) =>
      mergeWith({}, localRelationship, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = relationshipSchema.validate(
      omit(
        localRelationship,
        "_id",
        "suppliers",
        "shippers",
        "commodities",
        "receivers",
        "customers"
      ),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRelationship]);

  const { cancelConfirm } = useConfirmBeforeLeave(localRelationship);
  return (
    <Box>
      <FormDivider
        variant="fullWidth"
        text={capitalize(t("business:relation.details", "Relation Details"))}
      />
      <Stack direction="row" spacing={2} width="100%">
        <BusinessEntitySelectContainer
          businessEntityType={BusinessEntityType.Supplier}
          multiple={true}
          value={localRelationship?.supplierIds || []}
          onChange={(supplierIds) => {
            onChange({ supplierIds });
          }}
          inputProps={{
            helperText: t(
              "business:relation.leaveEmptySupplier",
              "Leave empty to allow any suppliers"
            ),
            required: false,
          }}
        />
        <BusinessEntitySelectContainer
          businessEntityType={BusinessEntityType.Shipper}
          multiple={true}
          value={localRelationship?.shipperIds || []}
          onChange={(shipperIds) => {
            onChange({ shipperIds });
          }}
        />

        <GoodProfileSelectContainer
          multiple={true}
          value={localRelationship?.commodityIds || []}
          onChange={(commodityIds) => {
            onChange({ commodityIds });
          }}
        />

        <BusinessEntitySelectContainer
          businessEntityType={BusinessEntityType.Receiver}
          multiple={true}
          value={localRelationship?.receiverIds || []}
          onChange={(receiverIds) => {
            onChange({ receiverIds });
          }}
        />
        <BusinessEntitySelectContainer
          businessEntityType={BusinessEntityType.Customer}
          multiple={true}
          value={localRelationship?.customerIds || []}
          onChange={(customerIds) => {
            onChange({ customerIds });
          }}
          inputProps={{
            helperText: t(
              "business:relation.leaveEmptyCustomer",
              "Leave empty to allow all customers"
            ),
            required: false,
          }}
        />
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (validate()) {
              cancelConfirm();
              onSave(localRelationship as NewRelationshipInput);
            }
          }}
          id="saveRelationshipButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default RelationshipForm;
