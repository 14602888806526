import ReceiverGoodsList, { ReceiverGoodsListProps } from "./ReceiverGoodsList";
import {
  ShipmentCommodityType,
  useGetBusinessEntityDetailsQuery,
} from "../../../../graphql/generated";
import { useLocation } from "react-router-dom";
import { Feature, useFeature } from "../../../account/Access/FeatureGuard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export type ReceiverGoodsListContainerProps = Omit<
  ReceiverGoodsListProps,
  "receiverDetails" | "receiverDetailsLoading" | "bestBuyOptionEnabled"
>;

export default function ReceiverGoodsListContainer(
  props: ReceiverGoodsListContainerProps
) {
  const { pathname } = useLocation();
  const isPortalAccess = pathname.includes("customer-portal");
  const { isAvailable: isCommodityManagementAvailable } = useFeature(
    Feature.CommodityManagement
  );
  const commodityType = useSelector(
    (state: RootState) => state.shipmentReducer.shipment.commodityType
  );

  const receiverDetailsQuery = useGetBusinessEntityDetailsQuery(
    {
      id: props.shipmentLocation.receiver || "",
    },
    {
      enabled: !!props.shipmentLocation.receiver,
    }
  );

  const receiverDetails = receiverDetailsQuery.data?.businessEntityById || null;

  return (
    <ReceiverGoodsList
      receiverDetails={receiverDetails}
      receiverDetailsLoading={receiverDetailsQuery.isLoading}
      {...props}
      bestBuyOptionEnabled={
        !isPortalAccess &&
        isCommodityManagementAvailable &&
        commodityType === ShipmentCommodityType.Liquid
      }
    />
  );
}
