import { Grid } from "@mui/material";
import enumLabel from "../../../utils/labels/enumLabel";
import { ConditionValueSelectorComponent } from "./ConditionForm";
import {
  BillingRuleConditionParameter,
  BillingRuleConditionParameterType,
} from "../../../graphql/generated";
import FuelCostAreaSelect from "./value-selectors/FuelCostAreaSelect";

const ConditionValueSelectorComponentByTarget: {
  [key in BillingRuleConditionParameterType]: ConditionValueSelectorComponent | null;
} = {
  [BillingRuleConditionParameterType.FuelCostArea]: FuelCostAreaSelect,
};

const conditionParameterLabels: Partial<
  Record<BillingRuleConditionParameterType, string>
> = {
  [BillingRuleConditionParameterType.FuelCostArea]: "Fuel Cost Area",
};

type ConditionParameterFormProps = {
  conditionParameter: BillingRuleConditionParameter;
  onChange: (conditionParameter: BillingRuleConditionParameter) => void;
};

const ConditionParameterForm = ({
  conditionParameter,
  onChange,
}: ConditionParameterFormProps) => {
  const ValueSelectorComponent =
    ConditionValueSelectorComponentByTarget[conditionParameter.type];
  return (
    <Grid container direction="row" alignItems="center" spacing={1} flex={1}>
      {conditionParameter.type && ValueSelectorComponent ? (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <ValueSelectorComponent
            label={
              conditionParameterLabels[conditionParameter.type] ||
              enumLabel(conditionParameter.type)
            }
            value={conditionParameter.value}
            onChange={(value) =>
              onChange({
                ...conditionParameter,
                value,
              })
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ConditionParameterForm;
