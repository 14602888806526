import {
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  addWeeks,
  addYears,
  format,
  getDay,
  getWeeksInMonth,
  isValid,
  parse,
  setDay,
  startOfMonth,
} from "date-fns";
import { ValidationResult } from "joi";
import {
  isArray,
  isEqualWith,
  isFunction,
  isNull,
  omit,
  omitBy,
  pickBy,
  range,
  upperFirst,
} from "lodash";
import { RRule, Weekday, Frequency as RRuleFrequency, datetime } from "rrule";
import { Frequency, ShipmentRecurrence } from "../../../../graphql/generated";
import {
  NewShipmentInputData,
  PredefinedRecurrence,
} from "../../../../redux/slices/Types";
import getFieldError from "../../../../utils/form/getFieldError";
import EnumSelect from "../../../common/EnumSelect";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";

type ShipmentCustomRecurrenceFormProps = {
  recurrence: ShipmentRecurrence;
  validationResult: ValidationResult<NewShipmentInputData> | null;
  onChange: (recurrence: ShipmentRecurrence) => void;
};

const frequencyUnitLabels: { [key in Frequency]: string } = {
  [Frequency.Daily]: "day",
  [Frequency.Weekly]: "week",
  [Frequency.Monthly]: "month",
  [Frequency.Yearly]: "year",
  [Frequency.Minutely]: "minute",
  [Frequency.Hourly]: "hour",
  [Frequency.Secondly]: "second",
};

const ShipmentCustomRecurrenceForm = ({
  recurrence,
  validationResult,
  onChange,
}: ShipmentCustomRecurrenceFormProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <TextField
          fullWidth
          label="Every"
          value={String(recurrence.interval)}
          error={
            getFieldError(validationResult, "recurrence.interval")
              ? true
              : false
          }
          helperText={getFieldError(validationResult, "recurrence.interval")}
          name="recurrence.interval"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange({
              ...recurrence,
              interval: parseInt(event.target.value) || 1,
            })
          }
        />
      </Grid>
      <Grid item md={6}>
        <EnumSelect
          fullWidth
          size="small"
          enumObject={pickBy(Frequency, (freq) =>
            [Frequency.Daily, Frequency.Weekly, Frequency.Monthly].includes(
              freq
            )
          )}
          optionLabel={(frequencyEnum) =>
            frequencyUnitLabels[frequencyEnum] +
            (recurrence.interval > 1 ? "s" : "")
          }
          value={recurrence.freq}
          onChange={(event, value) => {
            if (!value) {
              return;
            }
            onChange({
              ...recurrence,
              freq: value,
              byweekday: [],
              bymonthday: [],
            });
          }}
        />
      </Grid>
      {recurrence.freq === Frequency.Weekly ? (
        <Grid item md={12}>
          <ToggleButtonGroup
            value={(recurrence.byweekday || []).map(
              (weekday) => weekday.weekday
            )}
            onChange={(event, value: number[] | null) => {
              if (!value) {
                return;
              }
              onChange({
                ...recurrence,
                byweekday: value.map((weekday) => ({
                  weekday,
                })),
              });
            }}
            color="primary"
            aria-label="recurrence days"
            fullWidth
          >
            {[...range(1, 7), 0].map((day) => {
              return (
                <ToggleButton value={day === 0 ? 7 : day - 1}>
                  {format(setDay(new Date(), day), "E")}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      ) : null}

      {recurrence.freq === Frequency.Monthly ? (
        <Grid item md={12}>
          <ShipmentPredefinedRecurrenceSelect
            recurrence={{
              ...recurrence,
              dtstart: new Date(recurrence.dtstart),
            }}
            date={new Date(recurrence.dtstart)}
            onChange={(updatedRecurrence) => {
              if (!updatedRecurrence) {
                return null;
              }
              onChange({
                ...updatedRecurrence,
                until: recurrence.until,
              });
            }}
            availablePredefinedRecurrences={[
              PredefinedRecurrence.EVERY_MONTH_ON_DATE,
              PredefinedRecurrence.EVERY_MONTH_ON_FIRST_DAY,
              PredefinedRecurrence.EVERY_MONTH_ON_LAST_DAY,
              PredefinedRecurrence.EVERY_MONTH_ON_NTH_DAY,
            ]}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const ShipmentPredefinedRecurrenceSelect = ({
  value,
  recurrence,
  date,
  onChange,
  availablePredefinedRecurrences,
}: {
  value?: PredefinedRecurrence | null;
  recurrence?: Omit<ShipmentRecurrence, "until"> | null;
  date: Date | null;
  onChange: (
    recurrence: Omit<ShipmentRecurrence, "until"> | null,
    predefinedRecurrence: PredefinedRecurrence
  ) => void;
  availablePredefinedRecurrences?: PredefinedRecurrence[];
}) => {
  const start = recurrence?.dtstart
    ? new Date(recurrence.dtstart)
    : date
    ? new Date(date)
    : null;
  return (
    <EnumSelect
      label="Repeat"
      size="small"
      fullWidth
      required
      disabled={!recurrence?.dtstart && !date}
      enumObject={pickBy(
        availablePredefinedRecurrences
          ? pickBy(PredefinedRecurrence, (predefinedRecurrence) =>
              availablePredefinedRecurrences.includes(predefinedRecurrence)
            )
          : PredefinedRecurrence,
        (predefinedRecurrence) => {
          const configuration =
            predefinedRecurrenceConfiguration[predefinedRecurrence];
          if (configuration.isApplicable) {
            return configuration.isApplicable(start);
          }
          return true;
        }
      )}
      value={
        value
          ? value
          : recurrence
          ? getPredefinedFromRecurrence(recurrence) ||
            PredefinedRecurrence.CUSTOM
          : null
      }
      optionLabel={(predefinedRecurrence) => {
        if (predefinedRecurrence === PredefinedRecurrence.CUSTOM) {
          return "Custom";
        }
        if (predefinedRecurrence === PredefinedRecurrence.ONE_TIME) {
          return "One time";
        }
        if (!start) {
          return "N/A";
        }
        const predefinedRecurrenceValue = getRecurrenceFromPredefined(
          predefinedRecurrence,
          start,
          recurrence?.interval
        );
        if (!predefinedRecurrenceValue) {
          return "Custom";
        }
        return shipmentRecurrenceLabel(predefinedRecurrenceValue) || "Custom";
      }}
      onChange={(event, predefinedRecurrence) => {
        const updatedRecurrence =
          predefinedRecurrence && start
            ? getRecurrenceFromPredefined(
                predefinedRecurrence,
                start,
                recurrence?.interval
              )
            : null;

        if (updatedRecurrence) {
          onChange(
            updatedRecurrence,
            predefinedRecurrence || PredefinedRecurrence.CUSTOM
          );
        } else {
          onChange(null, predefinedRecurrence || PredefinedRecurrence.CUSTOM);
        }
      }}
    />
  );
};

type RecurrenceWithoutBounds = Omit<
  ShipmentRecurrence,
  "dtstart" | "until" | "interval"
>;

const jsDayToRruleDay = (day: number) => {
  switch (day) {
    case 0:
      return RRule.SU.weekday;
    case 1:
      return RRule.MO.weekday;
    case 2:
      return RRule.TU.weekday;
    case 3:
      return RRule.WE.weekday;
    case 4:
      return RRule.TH.weekday;
    case 5:
      return RRule.FR.weekday;
    case 6:
      return RRule.SA.weekday;
    default:
      throw new Error("Invalid day");
  }
};

const getMonthDayIndex = (date: Date) => {
  let index = 0;
  let iterator = startOfMonth(date);
  while (iterator < date) {
    iterator = addWeeks(iterator, 1);
    index++;
  }
  return index;
};

const jsMonthToRruleMonth = (month: number) => month + 1;

export const shipmentRecurrenceToRRule = (
  recurrence: Omit<ShipmentRecurrence, "until"> & {
    until?: Date;
  }
) => {
  function nullIfEmpty<T>(array: T[] | undefined | null) {
    return array?.length === 0 ? null : array;
  }
  const dtStart =
    recurrence && recurrence.dtstart ? new Date(recurrence.dtstart) : null;
  const rrule = dtStart
    ? new RRule({
        dtstart: datetime(
          dtStart.getFullYear(),
          jsMonthToRruleMonth(dtStart.getMonth()),
          dtStart.getDate()
        ),
        until: recurrence.until ? new Date(recurrence.until) : null,
        freq: RRuleFrequency[recurrence.freq],
        byweekday: nullIfEmpty(
          (recurrence.byweekday || []).map(
            (day) => new Weekday(day.weekday, day.n || undefined)
          )
        ),
        bymonth: nullIfEmpty(recurrence.bymonth),
        bymonthday: nullIfEmpty(recurrence.bymonthday),
        interval: recurrence.interval,
      })
    : null;
  return rrule;
};

export const predefinedRecurrenceConfiguration: {
  [key in PredefinedRecurrence]: {
    value:
      | RecurrenceWithoutBounds
      | ((date: Date | null) => RecurrenceWithoutBounds)
      | null;
    isApplicable?: (date: Date | null) => boolean;
  };
} = {
  [PredefinedRecurrence.ONE_TIME]: {
    value: null,
  },
  [PredefinedRecurrence.EVERY_DAY]: {
    value: {
      freq: Frequency.Daily,
    },
  },
  [PredefinedRecurrence.EVERY_WEEK_DAY]: {
    value: {
      freq: Frequency.Weekly,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    },
  },
  [PredefinedRecurrence.EVERY_WEEK_ON_DAY]: {
    value: (date) => ({
      freq: Frequency.Weekly,
      byweekday: date ? [{ weekday: jsDayToRruleDay(getDay(date)) }] : [],
    }),
  },
  [PredefinedRecurrence.EVERY_MONTH_ON_DATE]: {
    value: (date) => ({
      freq: Frequency.Monthly,
      bymonthday: date ? [date.getDate()] : [],
    }),
  },
  [PredefinedRecurrence.EVERY_MONTH_ON_FIRST_DAY]: {
    value: (date) => ({
      freq: Frequency.Monthly,
      byweekday: date ? [{ weekday: jsDayToRruleDay(getDay(date)), n: 1 }] : [],
    }),
    isApplicable: (date) => (date ? getMonthDayIndex(date) === 1 : false),
  },
  [PredefinedRecurrence.EVERY_MONTH_ON_LAST_DAY]: {
    value: (date) => ({
      freq: Frequency.Monthly,
      byweekday: date
        ? [{ weekday: jsDayToRruleDay(getDay(date)), n: -1 }]
        : [],
    }),
    isApplicable: (date) =>
      date ? getMonthDayIndex(date) === getWeeksInMonth(date) : false,
  },
  [PredefinedRecurrence.EVERY_MONTH_ON_NTH_DAY]: {
    value: (date) => ({
      freq: Frequency.Monthly,
      byweekday: date
        ? [
            {
              weekday: jsDayToRruleDay(getDay(date)),
              n: getMonthDayIndex(date),
            },
          ]
        : [],
    }),
    isApplicable: (date) => (date ? getMonthDayIndex(date) !== 1 : false),
  },
  [PredefinedRecurrence.EVERY_YEAR_ON_DATE]: {
    value: (date) => ({
      freq: Frequency.Yearly,
      interval: 1,
      bymonth: date ? [date.getMonth() + 1] : [],
      bymonthday: date ? [date.getDate()] : [],
    }),
  },
  [PredefinedRecurrence.CUSTOM]: {
    value: {
      freq: Frequency.Weekly,
      byweekday: [],
    },
  },
};

export const getRecurrenceFromPredefined = (
  predefinedRecurrence: PredefinedRecurrence,
  startDate: Date,
  interval: number = 1
): Omit<ShipmentRecurrence, "until"> | null => {
  const recurrenceValueOrFn = predefinedRecurrence
    ? predefinedRecurrenceConfiguration[predefinedRecurrence].value
    : null;
  const recurrence = isFunction(recurrenceValueOrFn)
    ? recurrenceValueOrFn(startDate)
    : recurrenceValueOrFn;
  return recurrence ? { ...recurrence, dtstart: startDate, interval } : null;
};

export const getPredefinedFromRecurrence = (
  recurrence: Omit<ShipmentRecurrence, "until"> | null | undefined
): PredefinedRecurrence | null => {
  const comparisonRecurrence = omit(
    omitBy(
      recurrence,
      (value) =>
        value === null ||
        value === undefined ||
        (isArray(value) && !value.length)
    ),
    "until",
    "dtstart",
    "interval"
  );
  if (!recurrence) {
    return PredefinedRecurrence.ONE_TIME;
  }
  const compareIgnoreNullUndefined = (a: any, b: any) => {
    if (a === null || a === undefined || b === null || b === undefined) {
      return true;
    }
    if (
      Object.values(a).some((value) => value === null || value === undefined) ||
      Object.values(b).some((value) => value === null || value === undefined)
    ) {
      return isEqualWith(
        omitBy(a, isNull),
        omitBy(b, isNull),
        compareIgnoreNullUndefined
      );
    }
  };

  const predefinedRecurrence = Object.entries(
    predefinedRecurrenceConfiguration
  ).find(([, { value }]) => {
    const predefinedRecurrenceValue = isFunction(value)
      ? value(new Date(recurrence.dtstart))
      : value;
    const serializedPredefinedRecurrenceValue = JSON.parse(
      JSON.stringify(predefinedRecurrenceValue)
    );
    return isFunction(value)
      ? isEqualWith(
          serializedPredefinedRecurrenceValue,
          comparisonRecurrence,
          compareIgnoreNullUndefined
        )
      : !!value &&
          isEqualWith(
            serializedPredefinedRecurrenceValue,
            comparisonRecurrence,
            compareIgnoreNullUndefined
          );
  })?.[0] as PredefinedRecurrence | undefined;
  return predefinedRecurrence || PredefinedRecurrence.CUSTOM;
};

export const shipmentRecurrenceLabel = (
  recurrence: Omit<ShipmentRecurrence, "until"> & {
    until?: Date;
  }
) => {
  const rrule = shipmentRecurrenceToRRule(recurrence);
  return upperFirst(rrule?.toText());
};

type ShipmentRecurrenceFormProps = {
  shipment: NewShipmentInputData;
  validationResult: ValidationResult<NewShipmentInputData> | null;
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  isChildLoad?: boolean;
};

const ShipmentRecurrenceForm = ({
  shipment,
  validationResult,
  onUpdate,
  isChildLoad,
}: ShipmentRecurrenceFormProps) => {
  const rrule = shipment.recurrence
    ? shipmentRecurrenceToRRule(shipment.recurrence)
    : null;
  // If there is no end date to the rrule, computing
  // all date becomes very expensive
  const recurrenceDates =
    rrule && shipment.recurrence?.until
      ? rrule.all().map((date) => date.toISOString())
      : [];

  const parsedShipmentDate = shipment.date
    ? parse(shipment.date, "yyyy-MM-dd", new Date())
    : null;
  const shipmentDateWithoutTz =
    parsedShipmentDate &&
    datetime(
      parsedShipmentDate.getFullYear(),
      jsMonthToRruleMonth(parsedShipmentDate.getMonth()),
      parsedShipmentDate.getDate()
    );
  return (
    <Grid item container spacing={3}>
      <Grid item xs={12} md={6}>
        <DatePicker
          label={"Start Date"}
          minDate={addYears(new Date(), -10)}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={getFieldError(validationResult, "date")}
              error={getFieldError(validationResult, "date") ? true : false}
              name={"date"}
              required={true}
              size={"small"}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          )}
          value={
            shipment.date
              ? parse(shipment.date, "yyyy-MM-dd", new Date())
              : null
          }
          onChange={(date) => {
            if (date && isValid(date)) {
              const recurrence =
                shipment.predefinedRecurrence && date
                  ? getRecurrenceFromPredefined(
                      shipment.predefinedRecurrence,
                      datetime(
                        date.getFullYear(),
                        jsMonthToRruleMonth(date.getMonth()),
                        date.getDate()
                      )
                    )
                  : null;
              onUpdate({
                date: format(date, "yyyy-MM-dd"),
                recurrence: recurrence
                  ? {
                      ...recurrence,
                      until: shipment.recurrence?.until,
                    }
                  : null,
              });
            }
          }}
        />
      </Grid>
      {!isChildLoad ? (
        <Grid item xs={12} md={6}>
          <ShipmentPredefinedRecurrenceSelect
            value={shipment.predefinedRecurrence}
            recurrence={
              shipment.recurrence
                ? {
                    ...shipment.recurrence,
                    interval: 1,
                  }
                : null
            }
            date={shipmentDateWithoutTz}
            onChange={(recurrence, predefinedRecurrence) => {
              onUpdate({
                recurrence: recurrence
                  ? {
                      ...recurrence,
                      until: shipment.recurrence?.until,
                    }
                  : null,
                predefinedRecurrence,
              });
            }}
          />
        </Grid>
      ) : null}

      {shipment.recurrence ? (
        <Grid item xs={12} md={6}>
          <DatePicker
            label={"End Date"}
            minDate={shipmentDateWithoutTz || new Date()}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={getFieldError(validationResult, "recurrence.until")}
                error={
                  getFieldError(validationResult, "recurrence.until")
                    ? true
                    : false
                }
                name={"recurrence.until"}
                required={true}
                size={"small"}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            )}
            value={shipment.recurrence?.until || null}
            onChange={(date) => {
              if (date && isValid(date)) {
                onUpdate({
                  recurrence: shipment.recurrence
                    ? {
                        ...shipment.recurrence,
                        until: date,
                      }
                    : null,
                });
              }
            }}
            closeOnSelect={false}
            renderDay={(day, selectedDays, pickersDayProps) => {
              const isoDayWithoutTz = datetime(
                day.getFullYear(),
                jsMonthToRruleMonth(day.getMonth()),
                day.getDate()
              ).toISOString();
              return (
                <PickersDay
                  {...pickersDayProps}
                  selected={
                    shipment.recurrence
                      ? recurrenceDates.includes(isoDayWithoutTz)
                      : pickersDayProps.selected
                  }
                />
              );
            }}
          />
        </Grid>
      ) : null}
      {shipment.recurrence ? (
        <Grid item xs={12} md={6}>
          {shipment.predefinedRecurrence === PredefinedRecurrence.CUSTOM ? (
            <ShipmentCustomRecurrenceForm
              recurrence={shipment.recurrence}
              validationResult={validationResult}
              onChange={(recurrence) => {
                onUpdate({ recurrence });
              }}
            />
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

export { ShipmentCustomRecurrenceForm };

export default ShipmentRecurrenceForm;
