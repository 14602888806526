import {
  GetShipmentQuery,
  ShipmentCommodityType,
  ShipmentLocationType,
} from "../../../graphql/generated";
import { Grid, Stack, Typography } from "@mui/material";
import {
  GroupWork,
  LocalGasStation,
  LocalShipping,
  LocationOn,
  Phone,
  Portrait,
  Style,
  Tag,
} from "@mui/icons-material";
import TrailerTypeIcon from "./TrailerFormIcon";
import { getConstraintValue } from "../ShipmentForm/TrailerRequirementsForm/TrailerRequirementsForm";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo/LabeledInfo";
import { sum } from "lodash";
import formatWeight from "../../../utils/labels/formatWeight";
import formatDistance from "../../../utils/labels/formatDistance";
import formatTemperature from "../../../utils/labels/formatTemperature";
import ShipmentSummary from "../ShipmentSummary";
import ShipmentSummaryContainer from "../ShipmentSummary/ShipmentSummaryContainer";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import {
  referenceNumberFieldLabels,
  referenceNumberFields,
} from "../ShipmentForm/ShipmentReferencesForm/ShipmentReferencesForm";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import GroupsDisplayContainer from "../../asset-management/GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import { useAuth } from "../../../providers/AuthProvider";
import { useTranslation } from "react-i18next";

type ShipmentGeneralInfosProps = {
  shipment: GetShipmentQuery["shipmentById"];
};

const ShipmentGeneralInfos = (props: ShipmentGeneralInfosProps) => {
  const { shipment } = props;
  const routeDistance = shipment.route?.routeDistance || 0;
  const customer = shipment.customer;
  const { userInfo } = useAuth();
  const { t } = useTranslation(["orders", "common"]);

  const pickupLocations = shipment.shipmentLocations.filter(
    (location) => location.locationType === ShipmentLocationType.Pickup
  );
  return (
    <Grid container mt={1} spacing={5}>
      <Grid item xs={12} lg={6}>
        <InfoBlock title={t("orderInformation")}>
          <Grid container>
            <Grid item md={6}>
              <LabeledInfo
                icon={<Tag />}
                title={t("orderNumber")}
                value={
                  <Stack direction="row" spacing={1}>
                    <Typography>{shipment.shipmentNumber}</Typography>
                    {shipment.commodityType === ShipmentCommodityType.Liquid ? (
                      <LocalGasStation />
                    ) : (
                      <LocalShipping />
                    )}
                  </Stack>
                }
              />
            </Grid>

            <Grid item md={6}>
              <LabeledInfo
                icon={<Portrait />}
                title={t("common:dispatcher")}
                value={userInfo?.name}
              />
            </Grid>

            <Grid item md={6}>
              <LabeledInfo
                title={t("common:tags")}
                value={<ChipTagsList value={shipment.tags || []} />}
                icon={<Style />}
              />
            </Grid>

            <Grid item md={6}>
              <LabeledInfo
                title={t("common:groups")}
                value={<GroupsDisplayContainer groupIds={shipment.groupIds} />}
                icon={<GroupWork />}
              />
            </Grid>
          </Grid>
        </InfoBlock>
      </Grid>
      <Grid item md={12} lg={6}>
        <InfoBlock title={t("common:brokerCustomer")}>
          <Grid container>
            <Grid item md={6}>
              <LabeledInfo
                icon={<Portrait />}
                title={
                  shipment.customer ? t("common:customer") : t("common:broker")
                }
                value={customer?.name}
              />
            </Grid>
            <Grid item md={6}>
              <LabeledInfo
                icon={<LocationOn />}
                title={t("customerAddress")}
                value={customer?.address?.label}
              />
            </Grid>
            <Grid item md={6}>
              <LabeledInfo
                icon={<Phone />}
                title={t("common:phoneNumber")}
                value={formatPhoneNumber(customer?.contact.phoneNumber || "")}
              />
            </Grid>

            {referenceNumberFields
              .filter((field) => shipment[field] && shipment[field]?.length)
              .map((referenceNumberField) => {
                const referenceNumberFieldLabel =
                  referenceNumberFieldLabels[referenceNumberField];
                return (
                  <Grid item xs={12} sm={6} key={referenceNumberField}>
                    <LabeledInfo
                      title={referenceNumberFieldLabel}
                      value={shipment[referenceNumberField]?.join(", ")}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </InfoBlock>
      </Grid>
      <Grid item xs={12} md={6}>
        <InfoBlock title={t("pickupDelivery")}>
          {(shipment.isSplit || shipment.dailyRotationCount) &&
          (shipment.childShipmentIds || []).length > 0 ? (
            <Stack spacing={2}>
              {(shipment.childShipmentIds || []).map((childShipmentId) => (
                <ShipmentSummaryContainer
                  shipmentId={childShipmentId}
                  assignable
                />
              ))}
            </Stack>
          ) : (
            <ShipmentSummary
              shipment={{
                ...shipment,
                route: shipment.route
                  ? {
                      ...shipment.route,
                      routeDistance,
                    }
                  : null,
              }}
            />
          )}
        </InfoBlock>
      </Grid>
      <Grid item xs={12} md={6}>
        <InfoBlock title={t("orderTrailerRequirements")}>
          <Stack
            direction="row"
            flexWrap={"wrap"}
            spacing={4}
            alignItems="center"
            justifyContent="center"
            sx={{ pt: 1 }}
          >
            <LabeledInfo
              icon={<TrailerTypeIcon type={shipment.trailerType} alt="" />}
              title=""
              value={[
                shipment.trailerType,
                ...(shipment.additionalTrailerTypes || []),
              ]
                .map(trailerTypeLabel)
                .join(", ")}
              centered
            />
            <LabeledInfo
              title={t("length")}
              value={`${
                getConstraintValue("length", shipment.constraints || [])
                  ? formatDistance(
                      getConstraintValue("length", shipment.constraints || [])
                    )
                  : ""
              }`}
            />
            <LabeledInfo
              title={t("width")}
              value={`${
                getConstraintValue("width", shipment.constraints || [])
                  ? formatDistance(
                      getConstraintValue("width", shipment.constraints || [])
                    )
                  : ""
              }`}
            />
            <LabeledInfo
              title={t("height")}
              value={`${
                getConstraintValue("height", shipment.constraints || [])
                  ? formatDistance(
                      getConstraintValue("height", shipment.constraints || [])
                    )
                  : ""
              }`}
            />
            <LabeledInfo
              title={t("weight")}
              value={formatWeight(
                sum(
                  pickupLocations
                    ?.map((location) =>
                      location.shippedGoods?.map(
                        (g) => (g.weight || 0) * g.quantity
                      )
                    )
                    .flat()
                )
              )}
            />
            <LabeledInfo
              title={t("temperatureSensitive")}
              value={((): string => {
                const minTemp = getConstraintValue(
                  "min_temperature",
                  shipment.constraints || []
                );
                const maxTemp = getConstraintValue(
                  "max_temperature",
                  shipment.constraints || []
                );
                if (minTemp === null || maxTemp === null) {
                  return "No";
                }
                return `${`Min Temp: ${formatTemperature(
                  minTemp
                )}`} - ${`Max Temp: ${formatTemperature(maxTemp)}`}`;
              })()}
            />
          </Stack>
        </InfoBlock>
      </Grid>

      <Grid item xs={12}>
        <CustomFieldsDisplayContainer customFields={shipment.customFields} />
      </Grid>
    </Grid>
  );
};

export default ShipmentGeneralInfos;
