import {
  Button,
  FormLabel,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ChargeType,
  GetInvoiceDetailsQuery,
  InvoiceStatus,
} from "../../../graphql/generated";
import InfoBlock from "../../common/InfoBlock";
import { useTranslation } from "react-i18next";
import RoleGuard from "../../account/Access/RoleGuard";
import { AttachMoney } from "@mui/icons-material";
import { useState } from "react";
import ObjectID from "bson-objectid";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import ShipmentLink from "../../common/ShipmentLink";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import DownloadSendInvoiceButtonsContainer from "../DownloadSendInvoiceButtons";
import InvoicePaymentsList from "../InvoicePaymentsList/InvoicePaymentsList";
import InvoicePaymentFormModal from "../../InvoicePaymentFormModal/InvoicePaymentFormModal";
import { formatCurrency } from "../../../utils/labels/formatCurrency";
import EnumSelect from "../../common/EnumSelect";
import invoiceStatusLabel from "../../../utils/labels/invoiceStatusLabel";
import { omitBy } from "lodash";
import InvoiceDetailsChargeTable from "./InvoiceDetailsChargeTable";

export type InvoiceDetailsData = GetInvoiceDetailsQuery["invoiceById"];

export type InvoiceDetailsProps = {
  invoice: InvoiceDetailsData;
  onSave?: (invoice: InvoiceDetailsData) => void;
  error?: Error | null;
};

const InvoiceDetails: React.FC<InvoiceDetailsProps> = (props) => {
  const { t } = useTranslation("finance");
  const [localInvoice, setLocalInvoice] = useState<InvoiceDetailsData>(
    props.invoice
  );

  const updateInvoice = (updates: Partial<InvoiceDetailsData>) => {
    setLocalInvoice((localInvoice) => ({
      ...localInvoice,
      ...updates,
    }));
  };

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentToEdit, setPaymentToEdit] =
    useState<NonNullable<InvoiceDetailsData["payments"]>[0] | null>(null);

  const isReadonly = [
    InvoiceStatus.Closed,
    InvoiceStatus.Cancelled,
    InvoiceStatus.Transferred,
  ].includes(localInvoice.status);

  return (
    <Stack spacing={2}>
      <ErrorMessage error={props.error} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <FormLabel>Status</FormLabel>
          <EnumSelect
            enumObject={omitBy(InvoiceStatus, (v) =>
              [InvoiceStatus.Paid, InvoiceStatus.Sent].includes(v)
            )}
            value={localInvoice.status}
            onChange={(e, status) => {
              if (!status) {
                return;
              }
              updateInvoice({ status });
            }}
            optionLabel={invoiceStatusLabel}
          />
        </Stack>
        <DownloadSendInvoiceButtonsContainer invoiceId={props.invoice._id} />
        <Button
          variant="contained"
          onClick={() => {
            if (props.onSave) {
              props.onSave(localInvoice);
            }
          }}
        >
          Save
        </Button>
      </Stack>
      <InfoBlock title={t("orders", "Orders")}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("orderNumber", "Order Number")}</TableCell>
              <TableCell>{t("deliveryDate", "Delivery Date")}</TableCell>
              <TableCell>{t("customer", "Customer")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localInvoice.shipments?.map((shipment) => (
              <TableRow>
                <TableCell>
                  <ShipmentLink shipment={shipment} />
                </TableCell>
                <TableCell>
                  {formatDateTime(shipment.trip?.lastDropoffTime)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </InfoBlock>
      {[ChargeType.Linehaul, ChargeType.Accessorial, ChargeType.Tax].map(
        (chargeType) => (
          <InvoiceDetailsChargeTable
            key={chargeType}
            chargeType={chargeType}
            readonly={isReadonly}
            charges={localInvoice.charges.filter(
              (c) => c.chargeType === chargeType
            )}
            onChange={(charges) => {
              updateInvoice({
                charges: [
                  ...localInvoice.charges.filter(
                    (c) => c.chargeType !== chargeType
                  ),
                  ...charges,
                ],
              });
            }}
          />
        )
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InfoBlock
            title={t("payments", "Payments")}
            action={
              <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setPaymentToEdit(null);
                      setPaymentModalOpen(true);
                    }}
                    startIcon={<AttachMoney />}
                    id="add-payment-button"
                  >
                    {t("addPayment", "Add payment")}
                  </Button>
                </RoleGuard>
              </Stack>
            }
          >
            <InvoicePaymentsList
              payments={localInvoice.payments || []}
              onEdit={(paymentId) => {
                const payment = localInvoice.payments?.find(
                  (p) => p._id === paymentId
                );
                if (payment) {
                  setPaymentToEdit(payment);
                  setPaymentModalOpen(true);
                }
              }}
              onDelete={(paymentId) => {
                updateInvoice({
                  payments: (localInvoice.payments || []).filter(
                    (p) => p._id !== paymentId
                  ),
                });
              }}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoBlock title="Totals">
            <TableContainer
              sx={{
                width: "100%",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Linehaul</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {formatTotal(localInvoice.charges, [ChargeType.Linehaul])}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Accessorial</TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {formatTotal(localInvoice.charges, [
                        ChargeType.Accessorial,
                      ])}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer
              sx={{
                width: "100%",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {formatTotal(localInvoice.charges, [
                        ChargeType.Linehaul,
                        ChargeType.Accessorial,
                      ])}
                    </TableCell>
                  </TableRow>
                  {localInvoice.charges
                    .filter((charge) => charge.chargeType === ChargeType.Tax)
                    .map((charge) => (
                      <TableRow>
                        <TableCell>{charge.label}</TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                          }}
                        >
                          {(
                            ((charge.unit * charge.rate) /
                              getTotal(localInvoice.charges, [
                                ChargeType.Linehaul,
                                ChargeType.Accessorial,
                              ])) *
                            100
                          ).toFixed(2)}
                          %
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "right",
                          }}
                        >
                          {formatCurrency(charge.unit * charge.rate)}
                        </TableCell>
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">TOTAL</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {formatTotal(localInvoice.charges)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </InfoBlock>
        </Grid>
      </Grid>

      <InvoicePaymentFormModal
        isOpen={isPaymentModalOpen}
        initialPayment={paymentToEdit}
        onClose={() => {
          setPaymentToEdit(null);
          setPaymentModalOpen(false);
        }}
        onSubmit={(payment) => {
          if (paymentToEdit) {
            updateInvoice({
              payments: localInvoice.payments?.map((p) =>
                p._id === paymentToEdit._id ? payment : p
              ),
            });
          } else {
            updateInvoice({
              payments: [
                ...(localInvoice.payments || []),
                {
                  ...payment,
                  _id: new ObjectID().toHexString(),
                },
              ],
            });
          }
          setPaymentToEdit(null);
          setPaymentModalOpen(false);
        }}
      />
    </Stack>
  );
};

export default InvoiceDetails;

const getTotal = (
  charges: GetInvoiceDetailsQuery["invoiceById"]["charges"],
  chargeTypes: ChargeType[] = Object.values(ChargeType)
) => {
  return charges
    .filter((c) => chargeTypes.includes(c.chargeType || ChargeType.Linehaul))
    .map((charge) => charge.unit * charge.rate)
    .reduce((a, b) => a + b, 0);
};

const formatTotal = (...args: Parameters<typeof getTotal>) => {
  return formatCurrency(getTotal(...args));
};
