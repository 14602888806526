import {
  TripSortCriteria,
  GetDriverShiftListQuery,
  AssetStatus,
  Time,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
// import driverShiftSchema from "../DriverShiftForm/driverShiftSchema";
// import driverShiftsMapping from "../../../utils/mappin-s/driverShifts";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import DriverShiftsCalendarContainer from "../DriverShiftsCalendar";
import { useState } from "react";
import DriverShiftFormContainer from "../DriverShiftForm";
import {
  dayEnumLabel,
  numberToDayEnum,
} from "../DriverShiftForm/DriverShiftForm";
import RoleGuard, { useRoles } from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

type DriverShiftListItem = GetDriverShiftListQuery["driverShifts"]["data"][0];

const timeLabel = (time: Time) =>
  `${String(time.hour).padStart(2, "0")}:${String(time.minute).padStart(
    2,
    "0"
  )}`;

export enum DriverShiftsTab {
  LIST,
  CALENDAR,
}

export default function DriverShiftsList({
  query,
  deleteMutation,
  onRefresh,
}: // onBulkCreate,
{
  query: QueryFn<DriverShiftListItem, "driverShifts", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  // onBulkCreate: (data: NewDriverShiftInput[]) => Promise<any>;
}) {
  const { t } = useTranslation("users");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedDriverShift, setSelectedDriverShift] =
    useState<DriverShiftListItem | null>(null);
  const [tab, setTab] = useState<DriverShiftsTab>(DriverShiftsTab.LIST);
  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<DriverShiftListItem, AssetStatus>[] = [
    {
      value: "label",
      label: t("driver.shift.label", "Label"),
      type: "string",
      sortBy: "label",
    },
    {
      value: (driverShift) =>
        driverShift.days
          .map((dayNumber) => numberToDayEnum[dayNumber])
          .map(dayEnumLabel)
          .join(", "),
      label: t("driver.shift.days", "Days"),
      type: "string",
      sortBy: "days",
    },
    {
      value: (driverShift) =>
        `${timeLabel(driverShift.startTime)} - ${timeLabel(
          driverShift.endTime
        )}`,
      label: t("driver.shift.time", "Time"),
      type: "string",
      sortBy: "startTime",
    },
    {
      value: (driverShift) =>
        `${driverShift.domicile?.name || ""} (${
          driverShift.driverIds?.length
            ? `${driverShift.driverIds?.length} driver(s)`
            : "All drivers"
        })`.trim(),
      label: t("driver.shift.target", "Target"),
      type: "string",
      sortBy: "domicile.name",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Tabs
        value={tab}
        onChange={(event, value) => {
          setTab(value);
        }}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label={t("driver.shift.list", "List")}
          value={DriverShiftsTab.LIST}
        />
        <Tab
          label={t("driver.shift.calendar", "Calendar")}
          value={DriverShiftsTab.CALENDAR}
        />
      </Tabs>

      <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            mb: 2,
          }}
        >
          {/* <ExcelImporter
          mapping={driverShiftsMapping}
          schema={driverShiftSchema}
          onBulkCreate={onBulkCreate}
        /> */}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            id="new-driverShift-button"
            onClick={() => {
              setFormOpen(true);
              setSelectedDriverShift(null);
            }}
          >
            {t("driver.shift.new", "New Shift")}
          </Button>
        </Box>
      </RoleGuard>

      <Box
        sx={{
          height: "calc(100% - 112px)",
        }}
      >
        {tab === DriverShiftsTab.LIST ? (
          <SmartLynksTable
            query={query}
            dataKey="driverShifts"
            fields={fields}
            onRecordClick={(driverShift) => {
              if (!hasWriteAccess) return;
              setFormOpen(true);
              setSelectedDriverShift(driverShift);
            }}
            additionalQueryVariables={{
              filter: {
                isAdhoc: {
                  $ne: true,
                },
              },
            }}
            deleteMutation={hasWriteAccess ? deleteMutation : undefined}
          />
        ) : null}
        {tab === DriverShiftsTab.CALENDAR ? (
          <DriverShiftsCalendarContainer />
        ) : null}
      </Box>
      <Dialog
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {capitalize(
            selectedDriverShift
              ? t("driver.shift.edit", "Edit Driver Shift")
              : t("driver.shift.new", "New Driver Shift")
          )}
        </DialogTitle>
        <DialogContent>
          {formOpen ? (
            <DriverShiftFormContainer
              driverShiftId={selectedDriverShift?._id}
              onSaved={() => {
                setFormOpen(false);
                onRefresh();
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
