import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  GetBusinessEntityDetailsQuery,
  GoodInput,
  GoodProfile,
  LoadType,
  NoteAccessLevel,
  ShipmentCommodityType,
  ShipmentLocationType,
  ShipmentNoteInput,
  TrailerType,
  TransactionInput,
} from "../../../graphql/generated";
import { v4 as uuid } from "uuid";
import {
  ShippedGoodsPayload,
  NewShipmentInputData,
  ReceivedGoodsPayload,
  ShipmentLocationInputData,
  ShipmentDocumentInputData,
  PredefinedRecurrence,
} from "../Types";
import flatMap from "lodash/flatMap";
import { poundsToKg } from "../../../utils/conversion/weight";
import LocaleProvider from "../../../providers/LocaleProvider";
import { isPickupLocation } from "../../../utils/location/isPickupLocation";

export const defaultReceiver: ShipmentLocationInputData = {
  _id: uuid(),
  location: {
    longitude: 0,
    latitude: 0,
  },
  shippedGoods: [],
  locationType: ShipmentLocationType.DropOff,
  receiver: "",
  receivedGoods: [],
  timeWindows: [],
};

export const defaultGood: GoodInput = {
  _id: uuid(),
  label: "",
  quantity: 1,
  weight: LocaleProvider.getWeightUnit() === "lb" ? poundsToKg(1) : 1,
};

export const defaultShipper: ShipmentLocationInputData = {
  _id: uuid(),
  location: {
    longitude: 0,
    latitude: 0,
  },
  shippedGoods: [],
  locationType: ShipmentLocationType.Pickup,
  receiver: "",
  shipper: "",
  receivedGoods: [],
  timeWindows: [],
};

interface ShipmentState {
  shipment: NewShipmentInputData;
  isCommodityDriven: boolean;
  goodProfiles: GoodProfile[];
  storageFacilitiesByReceiverId: Record<
    string,
    GetBusinessEntityDetailsQuery["businessEntityById"]["storageFacilities"]
  >;
}

export const defaultShipmentNote: ShipmentNoteInput = {
  content: "",
  receiver: null,
  shipper: null,
  accessLevel: Object.values(NoteAccessLevel),
};
export const defaultShipmentDocument: ShipmentDocumentInputData = {
  _id: uuid(),
  associatedCharge: "",
  isBillable: false,
  name: "",
  type: "",
  url: "",
  file: new File([], ""),
  receiver: null,
  shipper: null,
  accessLevel: Object.values(NoteAccessLevel),
};

export const initialShipment: NewShipmentInputData = {
  id: "",
  assignedCarriers: [],
  constraints: [],
  createdBy: "",
  customer: "",
  documents: [],
  notes: [],
  shipmentLocations: [defaultShipper, defaultReceiver],
  charges: [],
  billOfLadingNumber: null,
  postOfficeNumber: null,
  trailerType: TrailerType.Conestoga,
  loadType: LoadType.FullTruckLoad,
  date: null,
  predefinedRecurrence: PredefinedRecurrence.ONE_TIME,
  commodityType: ShipmentCommodityType.Dry,
};

const initialState: ShipmentState = {
  shipment: initialShipment,
  isCommodityDriven: false,
  goodProfiles: [],
  storageFacilitiesByReceiverId: {},
};

const shipmentSlice = createSlice({
  name: "shipper",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<string>) => {
      state.shipment.customer = action.payload;
    },
    addShipper: (state) => {
      state.shipment.shipmentLocations.push({
        ...defaultShipper,
        _id: uuid(),
        shippedGoods: [{ ...defaultGood, _id: uuid() }],
      });
    },
    addReceiver: (state) => {
      state.shipment.shipmentLocations.push({
        ...defaultReceiver,
        _id: uuid(),
      });
    },
    removeShipperOrReceiver: (state, action: PayloadAction<string>) => {
      const removedShipmentLocation = state.shipment.shipmentLocations.find(
        (shipmentLocation) => shipmentLocation._id === action.payload
      );

      state.shipment.shipmentLocations =
        state.shipment.shipmentLocations.filter(
          (shipmentLocation) => shipmentLocation._id !== action.payload
        );

      const goodIdsToClean = removedShipmentLocation?.shippedGoods.map(
        (shippedGood) => shippedGood._id
      );

      state.shipment.shipmentLocations.forEach((shipmentLocation) => {
        shipmentLocation.receivedGoods = shipmentLocation.receivedGoods.filter(
          (receivedGood) => !goodIdsToClean?.includes(receivedGood.goodId)
        );
      });
    },
    editShipmentLocationInput: (
      state,
      action: PayloadAction<{
        location: ShipmentLocationInputData;
      }>
    ) => {
      const index: number = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) =>
          shipmentLocation._id === action.payload.location._id
      );
      state.shipment.shipmentLocations[index] = action.payload.location;
    },
    setShipperGoods: (state, action: PayloadAction<ShippedGoodsPayload>) => {
      const index: number = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) => shipmentLocation._id === action.payload._id
      );
      state.shipment.shipmentLocations[index].shippedGoods =
        action.payload.goods;
      if (state.isCommodityDriven) {
        return;
      }
      const removedGoodIds = state.shipment.shipmentLocations[
        index
      ].shippedGoods
        .filter(
          (shippedGood) =>
            !action.payload.goods.find((good) => good._id === shippedGood._id)
        )
        .map((removedGood) => removedGood._id);
      // update receiver goods when some goods are removed from the form
      state.shipment.shipmentLocations.forEach((shipmentLocation) => {
        shipmentLocation.receivedGoods = shipmentLocation.receivedGoods.filter(
          (receivedGood) => !removedGoodIds.includes(receivedGood.goodId)
        );
      });

      // autofill received goods if there is only 1 receiver
      const dropOffShipmentLocations = state.shipment.shipmentLocations.filter(
        (sl) => sl.locationType === ShipmentLocationType.DropOff
      );
      const correspondingReceiverLocation =
        dropOffShipmentLocations.length === 1
          ? dropOffShipmentLocations[0]
          : null;
      if (correspondingReceiverLocation) {
        const pickupLocations = state.shipment.shipmentLocations.filter(
          (sl) => sl.locationType === ShipmentLocationType.Pickup
        );
        const allShippedGoods = flatMap(
          pickupLocations,
          (sl) => sl.shippedGoods
        );
        correspondingReceiverLocation.receivedGoods = allShippedGoods.map(
          (good) => ({
            goodId: good._id,
            quantity: good.quantity,
          })
        );
      }
    },
    setReceiverGoods: (state, action: PayloadAction<ReceivedGoodsPayload>) => {
      const index = state.shipment.shipmentLocations.findIndex(
        (shipmentLocation) => shipmentLocation._id === action.payload._id
      );
      if (index === -1) {
        return;
      }
      state.shipment.shipmentLocations[index].receivedGoods =
        action.payload.receivedGoods;
      // This is so the whole form gets rerendered
      // eslint-disable-next-line no-self-assign
      state.shipment = state.shipment;
    },
    addCharge: (state, action: PayloadAction<TransactionInput>) => {
      state.shipment.charges.push(action.payload);
    },
    setCharges: (state, action: PayloadAction<TransactionInput[]>) => {
      state.shipment.charges = action.payload;
    },
    editShipment: (state, action: PayloadAction<NewShipmentInputData>) => {
      state.shipment = action.payload;
      // If you are editing a liquid order, we switch to commodity driven
      if (state.shipment.commodityType === ShipmentCommodityType.Liquid) {
        state.isCommodityDriven = true;
        state.shipment.trailerType = TrailerType.Tanker;
        state.shipment.shipmentLocations =
          state.shipment.shipmentLocations.filter(
            (location) => !isPickupLocation(location)
          );
      }
    },
    resetShipmentForm: (state) => {
      state.shipment = initialShipment;
    },
    setIsCommodityDriven: (state, action: PayloadAction<boolean>) => {
      state.isCommodityDriven = action.payload;
      state.shipment = {
        ...initialShipment,
        commodityType: action.payload
          ? ShipmentCommodityType.Liquid
          : ShipmentCommodityType.Dry,
      };
      // Remove the default shipper location if we are commodity driven
      if (action.payload) {
        state.shipment.shipmentLocations =
          state.shipment.shipmentLocations.filter(
            (location) =>
              !(
                location.locationType === ShipmentLocationType.Pickup &&
                !location.shipper
              )
          );
      } else {
        if (
          !state.shipment.shipmentLocations.find(
            (sl) => sl.locationType === ShipmentLocationType.Pickup
          )
        ) {
          state.shipment.shipmentLocations = [
            defaultShipper,
            ...state.shipment.shipmentLocations,
          ];
        }
      }
    },
    setCommodityType: (state, action: PayloadAction<ShipmentCommodityType>) => {
      state.shipment.commodityType = action.payload;
      state.shipment.trailerType =
        action.payload === ShipmentCommodityType.Dry
          ? TrailerType.Conestoga
          : TrailerType.Tanker;
    },
    setGoodProfiles: (state, action: PayloadAction<GoodProfile[]>) => {
      state.goodProfiles = action.payload;
    },
  },
});

export const {
  setCustomer,
  addShipper,
  addReceiver,
  editShipmentLocationInput,
  setShipperGoods,
  editShipment,
  setReceiverGoods,
  resetShipmentForm,
  removeShipperOrReceiver,
  setIsCommodityDriven,
  setCommodityType,
  setGoodProfiles,
  addCharge,
  setCharges,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
