import {
  TripAssetTypes,
  useDeleteAssetLinkingMutation,
  useGetAssetLinkingsQuery,
} from "../../../graphql/generated";
import AssetLinkingsList, { AssetLinkingListProps } from "./AssetLinkingsList";

type AssetLinkingsListContainerProps = Omit<
  AssetLinkingListProps,
  "query" | "deleteMutation" | "onRefresh" | "onComplete"
>;

export default function AssetLinkingsListContainer(
  props: AssetLinkingsListContainerProps
) {
  const today = new Date().toISOString();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new asset linking has been generated
  const useGetAssetLinkingListQueryCustom: typeof useGetAssetLinkingsQuery = (
    variables,
    options
  ) => {
    const query = useGetAssetLinkingsQuery(
      {
        ...variables,
        filter: {
          $or: [
            {
              endDate: null,
            },
            {
              endDate: { $gt: today },
            },
          ],
          ...(props.assetType === TripAssetTypes.Driver
            ? { driver: props.assetId }
            : props.assetType === TripAssetTypes.Trailer
            ? { trailer: props.assetId }
            : props.assetType === TripAssetTypes.Tractor
            ? { tractor: props.assetId }
            : {}),
        },
      },
      {
        ...options,
      }
    );
    refetch = query.refetch;
    return query;
  };
  useGetAssetLinkingListQueryCustom.fetcher = useGetAssetLinkingsQuery.fetcher;
  useGetAssetLinkingListQueryCustom.getKey = useGetAssetLinkingsQuery.getKey;

  return (
    <AssetLinkingsList
      query={useGetAssetLinkingListQueryCustom}
      deleteMutation={useDeleteAssetLinkingMutation}
      onRefresh={() => {
        refetch();
      }}
      {...props}
    />
  );
}
