import { useCallback } from "react";
import { useParams } from "react-router-dom";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Invoice } from "../../graphql/generated";
import { useNavigationOptions } from "../LayoutRoutes";
import { useTranslation } from "react-i18next";
import InvoiceDetailsContainer from "../../components/accounting/InvoiceDetails";

function ViewInvoice() {
  const { t } = useTranslation("finance");
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (invoice: Pick<Invoice, "invoiceNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label={t("invoice.one", "Invoice")}
            suffix={invoice?.invoiceNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions, t]
  );
  if (!id) {
    return null;
  }
  return <InvoiceDetailsContainer invoiceId={id} onLoad={onLoad} />;
}

export default ViewInvoice;
