import {
  Business,
  Call,
  ContentCopy,
  Delete,
  Email,
  GroupWork,
  LiveHelp,
  LocationOn,
  Lock,
  ModeEditOutlineOutlined,
  Person,
  PushPin,
  Style,
  Tag,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MarkerF } from "@react-google-maps/api";
import { parse } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BusinessEntityType,
  GetBusinessEntityDetailsQuery,
  ShipmentReferenceNumberType,
} from "../../../graphql/generated";
import toLatLng from "../../../utils/geo/toLaLng";
import enumLabel from "../../../utils/labels/enumLabel";
import {
  fallbackTimezone,
  formatTime,
} from "../../../utils/labels/formatDateTime";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import formatUrl from "../../../utils/labels/formatUrl";
import personLabel from "../../../utils/labels/personLabel";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import Map from "../../common/Map/Map";
import { dayLabel } from "../OpeningHoursForm/OpeningHoursForm";
import commodityLabel from "../../../utils/labels/commodityLabel";
import RoleGuard from "../../account/Access/RoleGuard";
import FeatureGuard, { Feature } from "../../account/Access/FeatureGuard";
import { formatInTimeZone } from "date-fns-tz";
import { capitalize } from "lodash";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import GroupsDisplayContainer from "../GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import SupplierContractsListContainer from "../SuppliersList/SupplierContractsList";
import StorageFacilityLevelBar from "../../forecasting/ForecastingDashboard/StorageFacilityLevelBar";

type BusinessEntityDetailsData =
  GetBusinessEntityDetailsQuery["businessEntityById"];

type BusinessEntityDetailsProps = {
  businessEntity: BusinessEntityDetailsData;
  onEdit: () => void;
  onDelete: () => void;
};

function BusinessEntityDetails({
  businessEntity,
  onEdit,
  onDelete,
}: BusinessEntityDetailsProps) {
  const { t } = useTranslation(["business", "common", "orders", "assets"]);
  const [isAddressMapOpen, setIsAddressMapOpen] = useState(false);

  const businessEntityTypes = [
    businessEntity?.type,
    ...(businessEntity?.additionalTypes || []),
  ].filter(Boolean) as BusinessEntityType[];

  const isBusinessOneOfType = (types: BusinessEntityType[]) =>
    types.some((type) => businessEntityTypes.includes(type));

  const customerPortalUrl = businessEntity.accessToken
    ? `${window.location.origin}/customer-portal?token=${businessEntity.accessToken}`
    : null;
  return (
    <Box>
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <Stack direction="row" flexDirection="row-reverse" spacing={2}>
          <RoleGuard roles={["Carrier Admin"]}>
            <IconButton onClick={onDelete} color="error">
              <Delete />
            </IconButton>
          </RoleGuard>
        </Stack>
        <RoleGuard
          roles={[
            "Carrier Admin",
            "Manager",
            "Clerical",
            isBusinessOneOfType([
              BusinessEntityType.Customer,
              BusinessEntityType.Broker,
            ])
              ? "Accounting"
              : null,
          ]}
        >
          <Stack direction="row" flexDirection="row-reverse" spacing={2}>
            <IconButton onClick={onEdit} color="primary">
              <ModeEditOutlineOutlined />
            </IconButton>
          </Stack>
        </RoleGuard>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <InfoBlock title={t("business:details", "Business Details")}>
            <LabeledInfo
              title={t("business:name", "Name")}
              value={businessEntity.name}
              icon={<Business />}
            />
            {businessEntity.type !== BusinessEntityType.Carrier ? (
              <LabeledInfo
                title={t("business:types", "Types")}
                value={[
                  businessEntity.type,
                  ...(businessEntity.additionalTypes || []),
                ]
                  .map((entity) =>
                    t(
                      // @ts-expect-error
                      `business:businessEntityType.${entity}`,
                      enumLabel(entity)
                    )
                  )
                  .join(", ")}
                icon={<PushPin />}
              />
            ) : (
              <LabeledInfo
                title={t("business:carrier.types", "Carrier Types")}
                value={(businessEntity.trailerTypes || [])
                  .map(trailerTypeLabel)
                  .join(", ")}
                icon={<PushPin />}
              />
            )}
            <LabeledInfo
              title={t("business:code", "Business code")}
              value={businessEntity.code}
              icon={<Tag />}
            />
            {isBusinessOneOfType([
              BusinessEntityType.Broker,
              BusinessEntityType.Carrier,
            ]) ? (
              <LabeledInfo
                title="MC Number"
                value={businessEntity.mcNumber}
                icon={<Tag />}
              />
            ) : null}
            {isBusinessOneOfType([BusinessEntityType.Carrier]) ? (
              <>
                <LabeledInfo
                  title="SCAC"
                  value={businessEntity.standardCarrierAlphaCode}
                  icon={<Tag />}
                />
                <LabeledInfo
                  title="DOT#"
                  value={businessEntity.dotNumber}
                  icon={<Tag />}
                />
                <LabeledInfo
                  title="MC#"
                  value={businessEntity.mcNumber}
                  icon={<Tag />}
                />
                <LabeledInfo
                  title={capitalize(
                    t("business:payToProfile.one", "Pay to Profile")
                  )}
                  value={
                    businessEntity.payToProfileId ? (
                      <Link
                        href={`/payto/details/${businessEntity.payToProfileId}`}
                      >
                        {t("common:open", "Open")}
                      </Link>
                    ) : null
                  }
                  icon={<Tag />}
                />
              </>
            ) : null}

            <LabeledInfo
              title={t("assets:status", "Status")}
              value={t(
                `commo:statusTypes.${businessEntity.status}`,
                enumLabel(businessEntity.status) || "N/A"
              )}
              icon={<LiveHelp />}
            />
            <LabeledInfo
              title={t("common:tags")}
              value={<ChipTagsList value={businessEntity.tags || []} />}
              icon={<Style />}
            />
            <LabeledInfo
              title={t("common:groups")}
              value={
                <GroupsDisplayContainer groupIds={businessEntity.groupIds} />
              }
              icon={<GroupWork />}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={6}>
          <InfoBlock title={t("business:contactDetails", "Contact Details")}>
            <LabeledInfo
              title={t("business:title", "Title")}
              value={businessEntity.contact.title}
              icon={<Person />}
            />
            <LabeledInfo
              title={t("business:name", "Name")}
              value={personLabel(businessEntity.contact)}
              icon={<Person />}
            />
            <LabeledInfo
              title={t("common:email", "Email Address")}
              value={businessEntity.contact.email}
              icon={<Email />}
            />
            <LabeledInfo
              title={t("common:phoneNumber", "Phone Number")}
              value={formatPhoneNumber(businessEntity.contact.phoneNumber)}
              icon={<Call />}
            />
            <LabeledInfo
              title={t("common:faxNumber", "Fax Number")}
              value={businessEntity.contact.faxNumber}
              icon={<Call />}
            />
            <LabeledInfo
              title={t("common:url", "Website")}
              value={
                businessEntity.contact.url ? (
                  <Link
                    href={`${formatUrl(businessEntity.contact.url)}`}
                    component="a"
                    target="_blank"
                  >
                    {formatUrl(businessEntity.contact.url)}
                  </Link>
                ) : null
              }
            />
            <LabeledInfo
              title={t("common:extensionNumber", "Extension Number")}
              value={businessEntity.contact.extensionNumber}
              icon={<Call />}
            />
            <FeatureGuard feature={Feature.CustomerPortal}>
              {customerPortalUrl ? (
                <LabeledInfo
                  title={t("customer.portalAccessUrl", "Portal Access URL")}
                  value={
                    <>
                      <Link href={customerPortalUrl} component="a">
                        {customerPortalUrl}
                      </Link>
                      {/* copy button */}
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(customerPortalUrl);
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </>
                  }
                  icon={<Lock />}
                />
              ) : // copy button

              null}
            </FeatureGuard>
          </InfoBlock>
        </Grid>
        <Grid item sm={4}>
          <InfoBlock title={t("business:physicalAddress", "Physical Address")}>
            <LabeledInfo
              title={t("business:physicalAddress", "Physical Address")}
              value={businessEntity.address.label}
              icon={<LocationOn />}
              onClick={() => setIsAddressMapOpen(true)}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={4}>
          {isBusinessOneOfType([
            BusinessEntityType.Customer,
            BusinessEntityType.Broker,
          ]) ? (
            <InfoBlock title={t("business:billingAddress", "Billing Address")}>
              <LabeledInfo
                title={t("common:address.line1", "Address Line 1")}
                value={businessEntity.billingAddress?.line1}
              />
              <LabeledInfo
                title={t("common:address.line2", "Address Line 2")}
                value={businessEntity.billingAddress?.line2}
              />
              <LabeledInfo
                title={t("common:address.postalCode", "Postal Code")}
                value={businessEntity.billingAddress?.postalCode}
              />
              <LabeledInfo
                title={t("common:address.city", "City")}
                value={businessEntity.billingAddress?.city}
              />
              <LabeledInfo
                title={t("common:address.state", "State")}
                value={businessEntity.billingAddress?.state}
              />
              <LabeledInfo
                title={t("common:address.country", "Country")}
                value={businessEntity.billingAddress?.country}
              />
              <LabeledInfo
                title={t("common:email", "Adresse Email")}
                value={businessEntity.billingEmail}
              />
              <LabeledInfo
                title={t("business:billingTerms", "Billing Terms")}
                value={
                  businessEntity.billingTermsDay
                    ? t("business:countDays", {
                        count: businessEntity.billingTermsDay,
                      })
                    : null
                }
              />
              <LabeledInfo
                title={t("business:referenceNumberTypes", "Reference Number")}
                value={
                  <Stack spacing={1}>
                    {Object.values(ShipmentReferenceNumberType).map(
                      (refFieldType) => {
                        const defaultRefFields =
                          businessEntity.defaultReferenceNumbers?.filter(
                            (refField) =>
                              refField.referenceNumberType === refFieldType
                          );

                        return (
                          <Stack>
                            <Typography>
                              {t(
                                `orders:referenceNumberType.${
                                  refFieldType as ShipmentReferenceNumberType
                                }`
                              )}
                            </Typography>
                            <Stack direction="row" spacing={1}>
                              {defaultRefFields && defaultRefFields.length !== 0
                                ? defaultRefFields.map((refField) => (
                                    <Chip
                                      label={refField.referenceNumberValue}
                                    />
                                  ))
                                : "N/A"}
                            </Stack>
                          </Stack>
                        );
                      }
                    )}
                  </Stack>
                }
              />
            </InfoBlock>
          ) : null}
        </Grid>
        <Grid item sm={4}>
          {isBusinessOneOfType([
            BusinessEntityType.Customer,
            BusinessEntityType.Broker,
          ]) ? (
            <InfoBlock title={t("business:remitAddress", "Remit Address")}>
              <LabeledInfo
                title={t("business:remitCompanyName", "Remit Company Name")}
                value={businessEntity.remitCompanyName}
              />
              <LabeledInfo
                title={t("common:address.line1", "Address Line 1")}
                value={businessEntity.remitAddress?.line1}
              />
              <LabeledInfo
                title={t("common:address.line2", "Address Line 2")}
                value={businessEntity.remitAddress?.line2}
              />
              <LabeledInfo
                title={t("common:address.postalCode", "Postal Code")}
                value={businessEntity.remitAddress?.postalCode}
              />
              <LabeledInfo
                title={t("common:address.city", "City")}
                value={businessEntity.remitAddress?.city}
              />
              <LabeledInfo
                title={t("common:address.state", "State")}
                value={businessEntity.remitAddress?.state}
              />
              <LabeledInfo
                title={t("common:address.country", "Country")}
                value={businessEntity.remitAddress?.country}
              />
              <LabeledInfo
                title={t("remitEmail", "Email")}
                value={businessEntity.remitEmail}
              />
            </InfoBlock>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <CustomFieldsDisplayContainer
            customFields={businessEntity.customFields}
          />
        </Grid>
        {isBusinessOneOfType([
          BusinessEntityType.Shipper,
          BusinessEntityType.Receiver,
        ]) ? (
          <Grid item sm={12}>
            <InfoBlock title={t("business:operatingHours", "Operating Hours")}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{capitalize(t("days_other", "Days"))}</TableCell>
                    <TableCell>{t("openingTime", "Opening time")}</TableCell>
                    <TableCell>{t("closingTime", "Closing time")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessEntity.openingSchedules?.map((schedule) => (
                    <TableRow>
                      <TableCell>
                        {schedule.days.map(dayLabel).join(", ")}
                      </TableCell>
                      <TableCell>
                        {/* Opening and closing hours are relative times, not absolute, so we can display them as is */}
                        {/* then display the timezone separately */}
                        {formatTime(
                          parse(schedule.openingTime, "HH:mm", new Date()),
                          null,
                          false
                        )}
                        {formatInTimeZone(
                          new Date(),
                          businessEntity.addressTimezone || fallbackTimezone,
                          " (zzz)"
                        )}
                      </TableCell>
                      <TableCell>
                        {formatTime(
                          parse(schedule.closingTime, "HH:mm", new Date()),
                          null,
                          false
                        )}
                        {formatInTimeZone(
                          new Date(),
                          businessEntity.addressTimezone || fallbackTimezone,
                          " (zzz)"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </InfoBlock>
          </Grid>
        ) : null}

        <Grid item sm={12}>
          <InfoBlock
            title={t("business:additionalContacts", "Additional Contacts")}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("common:title", "Title")}</TableCell>
                  <TableCell>{t("common:firstName", "First Name")}</TableCell>
                  <TableCell>{t("common:lastName", "Last Name")}</TableCell>
                  <TableCell>{t("common:email", "Adresse Email")}</TableCell>
                  <TableCell>
                    {t("common:extensionNumber", "Extension Number")}
                  </TableCell>
                  <TableCell>{t("common:faxNumber", "Fax Number")}</TableCell>
                  <TableCell>{t("common:url", "Website")}</TableCell>
                  <TableCell>
                    {t("common:phoneNumber", "Phone Number")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessEntity.additionalContacts?.map((contact) => (
                  <TableRow>
                    <TableCell>{contact.title}</TableCell>
                    <TableCell>{contact.firstname}</TableCell>
                    <TableCell>{contact.lastname}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>{contact.extensionNumber}</TableCell>
                    <TableCell>{contact.faxNumber}</TableCell>
                    <TableCell>
                      <Link
                        href={`${formatUrl(contact.url)}`}
                        component="a"
                        target="_blank"
                      >
                        {formatUrl(contact.url)}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {formatPhoneNumber(contact.phoneNumber)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>

        <Grid item sm={12}>
          <InfoBlock title={t("common:documents.many")}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("common:name")}</TableCell>
                  <TableCell
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {t("common:documents.one")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessEntity.documents?.map((document) => (
                  <TableRow>
                    <TableCell>{document.name}</TableCell>
                    <TableCell>
                      <Link href={document.url} component="a" target="_blank">
                        Open
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>

        <FeatureGuard feature={Feature.CommodityManagement}>
          {isBusinessOneOfType([BusinessEntityType.Receiver]) ? (
            <Grid item sm={12}>
              <InfoBlock title="Tanks">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tank ID</TableCell>
                      <TableCell>Capacity</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell>Commodity</TableCell>
                      <TableCell>Default Supplier</TableCell>
                      <TableCell>Level</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {businessEntity.storageFacilities?.map(
                      (storageFacility) => (
                        <TableRow>
                          <TableCell>{storageFacility.identifier}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {storageFacility.capacity}
                            <br />
                            {storageFacility.safeFillLevel ? (
                              <Typography variant="caption" color="green">
                                Safe Fill: {storageFacility.safeFillLevel}
                              </Typography>
                            ) : null}
                            {storageFacility.safeFillLevel &&
                            storageFacility.shutDownLevel ? (
                              <br />
                            ) : (
                              ""
                            )}
                            {storageFacility.shutDownLevel ? (
                              <Typography variant="caption" color="red">
                                Shut Down: {storageFacility.shutDownLevel}
                              </Typography>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {enumLabel(storageFacility.unit)}
                          </TableCell>
                          <TableCell>
                            {commodityLabel(storageFacility.commodity)}
                          </TableCell>
                          <TableCell>
                            {storageFacility.defaultSupplier
                              ? `
                            (${storageFacility.defaultSupplier?.code})
                            ${storageFacility.defaultSupplier?.name}`
                              : null}
                          </TableCell>
                          <TableCell>
                            <StorageFacilityLevelBar
                              storageFacility={storageFacility}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </InfoBlock>
            </Grid>
          ) : null}
        </FeatureGuard>

        {businessEntity.type === BusinessEntityType.Supplier ? (
          <Grid item xs={12}>
            <InfoBlock
              title={capitalize(
                t("business:supplier.contract.many", "Contrats")
              )}
            >
              <SupplierContractsListContainer />
            </InfoBlock>
          </Grid>
        ) : null}
      </Grid>

      <Dialog
        open={isAddressMapOpen}
        onClose={() => setIsAddressMapOpen(false)}
      >
        <DialogTitle>{businessEntity.address.label}</DialogTitle>

        <DialogContent>
          <Map center={toLatLng(businessEntity.address.coordinates)} zoom={13}>
            <MarkerF position={toLatLng(businessEntity.address.coordinates)} />
          </Map>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsAddressMapOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default BusinessEntityDetails;
