import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Theme,
  useMediaQuery,
} from "@mui/material";
import ShipmentDetailsForm from "./ShipmentDetailsForm";
import TrailerRequirementsForm from "./TrailerRequirementsForm";
import {
  FieldErrors,
  FormError,
  NewShipmentInputData,
} from "../../../redux/slices/Types";
import { useMemo, useState } from "react";
import { Save } from "@mui/icons-material";
import { ShipmentDetailsProps } from "../ShipmentDetails/ShipmentDetails";
import { useTranslation } from "react-i18next";
import ShipmentGeneralInfos from "../ShipmentDetails/ShipmentGeneralInfos";

type LocationDrivenShipmentFormProps = {
  shipmentInput: NewShipmentInputData;
  shipmentDetails: ShipmentDetailsProps["shipment"] | null;
  errors?: FieldErrors;
  shipmentLocationErrors?: FormError[];
  loading: boolean;
  validating: boolean;
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  onChange: (name: string, value: string | null | undefined) => void;
  onValidate: () => Promise<boolean>;
  onSubmit: () => Promise<void>;
  isChildLoad?: boolean;
  isSplit?: boolean;
  isEdit?: boolean;
};

const LocationDrivenShipmentForm = ({
  shipmentInput,
  shipmentDetails,
  errors,
  loading,
  validating,
  shipmentLocationErrors,
  onUpdate: handleUpdate,
  onChange: handleChange,
  onValidate,
  onSubmit,
  isChildLoad,
  isSplit,
  isEdit: editMode,
}: LocationDrivenShipmentFormProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { t } = useTranslation("orders");

  const steps = useMemo(
    () => [
      {
        label: t("orderDetails"),
      },
      {
        label: t("orderTrailerRequirements"),
      },
      {
        label: t("summary"),
      },
    ],
    [t]
  );

  const renderStepperContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ShipmentDetailsForm
            shipment={shipmentInput}
            errors={errors}
            shipmentLocationErrors={shipmentLocationErrors || []}
            onChange={handleChange}
            onUpdate={handleUpdate}
            shipmentLocations={shipmentInput.shipmentLocations}
            isChildLoad={isChildLoad}
            isSplit={isSplit}
          />
        );
      case 1:
        return (
          <TrailerRequirementsForm
            shipment={shipmentInput}
            onUpdate={handleUpdate}
          />
        );
      case 2:
        return shipmentDetails ? (
          <ShipmentGeneralInfos shipment={shipmentDetails} />
        ) : null;
    }
  };

  const handleNext = async () => {
    const isValid = await onValidate();
    if (isValid) {
      if (activeStep === steps.length - 1) {
        await onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      className="test"
      sx={{ height: "100%", pr: 1, pl: 1 }}
    >
      <Grid container item spacing={4} sx={{ height: "calc(100% - 44.5px)" }}>
        <Grid item xs={12} md={2}>
          <Stepper
            sx={{ height: "inherit" }}
            activeStep={activeStep}
            orientation={isMdUp ? "vertical" : "horizontal"}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={10} sx={{ overflowY: "auto", height: "100%" }}>
          {renderStepperContent(activeStep)}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: {
              xs: "absolute",
            },
            bottom: {
              xs: "10px",
            },
            right: {
              xs: "10px",
            },
          }}
        >
          {activeStep !== 0 && (
            <Button
              color="lightPrimary"
              onClick={handleBack}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Previous
            </Button>
          )}
          {activeStep === 2 && loading ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<Save />}
              variant="outlined"
              id="shipment-form-saving-button"
            >
              Saving...
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading || validating}
              color="secondary"
              variant="contained"
              onClick={handleNext}
              id="shipment-form-next-button"
            >
              {activeStep !== 2 ? "Next" : editMode ? "Save changes" : "Create"}
            </LoadingButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LocationDrivenShipmentForm;
