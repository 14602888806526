import { MeasurementSystem } from "../../graphql/generated";
import LocaleProvider, { Locale } from "../../providers/LocaleProvider";

export function localDistanceToMeters(valueInLocalDistance: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return valueInLocalDistance * 0.3048;
  }
  return valueInLocalDistance;
}

export function metersToLocalDistance(valueInMeters: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return Number.parseFloat((valueInMeters / 0.3048).toFixed(2));
  }
  return valueInMeters;
}

export function mileage(valueInMeters: number) {
  // Mile
  if (LocaleProvider.getMeasurementSystem() === MeasurementSystem.Imperial) {
    return parseFloat((valueInMeters / 1609.34).toFixed(2));
  }
  // Km
  return parseFloat(Math.round(valueInMeters / 1000).toFixed(2));
}

export function mileageToMeters(valueInLocalMileage: number) {
  // Mile to m
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return valueInLocalMileage * 1609.34;
  }
  // Km to m
  return valueInLocalMileage * 1000;
}

export function feetInchesToMeters(feet: number, inches: number = 0) {
  return (feet * 12 + inches) * 0.0254;
}

export function feetToFeetInches(feet: number) {
  const feetInt = Math.floor(feet);
  const inches = Math.round((feet - feetInt) * 12);
  return {
    feet: feetInt + (inches === 12 ? 1 : 0),
    inches: inches === 12 ? 0 : inches,
  };
}

export function feetInchesToFeet(feet: number = 0, inches: number = 0) {
  return feet + inches / 12;
}
