import {
  TripSortCriteria,
  GetDriverShiftAssignmentsQuery,
  Scalars,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useRoles } from "../../account/Access/RoleGuard";
import driverLabel from "../../../utils/labels/driverLabel";
import DriverShiftAssignmentFormModalContainer from "../DriverShiftAssignmentFormModal";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../utils/labels/formatDateTime";

type DriverShiftAssignmentListItem =
  GetDriverShiftAssignmentsQuery["driverShiftAssignments"]["data"][0];

export type DriverShiftAssignmentListProps = {
  query: QueryFn<
    DriverShiftAssignmentListItem,
    "driverShiftAssignments",
    TripSortCriteria
  >;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  driverId: Scalars["ObjectId"];
};

export default function DriverShiftAssignmentsList({
  query,
  deleteMutation,
  onRefresh,
  driverId,
}: DriverShiftAssignmentListProps) {
  const { t } = useTranslation("assets");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedDriverShiftAssignment, setSelectedDriverShiftAssignment] =
    useState<DriverShiftAssignmentListItem | null>(null);

  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<DriverShiftAssignmentListItem>[] = [
    {
      value: (driverShiftAssignment) =>
        driverLabel(driverShiftAssignment.driver),
      label: t("driver.one", "Driver"),
      type: "string",
    },
    {
      value: (driverShiftAssignment) =>
        driverShiftAssignment.driverShift?.label || "",
      label: t("driverShift.one", "Driver Shift"),
      type: "string",
    },
    {
      value: (driverShiftAssignment) =>
        driverShiftAssignment.startDate
          ? formatDateTime(
              driverShiftAssignment.startDate,
              driverShiftAssignment.driver?.domicileEntity?.addressTimezone
            )
          : null,
      label: t(
        "driverShiftAssignmentColumns.startDateAndTime",
        "Start Date & Time"
      ),
      type: "string",
      sortBy: "startDate",
    },
    {
      value: (driverShiftAssignment) =>
        driverShiftAssignment.endDate
          ? formatDateTime(
              driverShiftAssignment.endDate,
              driverShiftAssignment.driver?.domicileEntity?.addressTimezone
            )
          : null,
      label: t(
        "driverShiftAssignmentColumns.endDateAndTime",
        "End Date & Time"
      ),
      type: "string",
      sortBy: "endDate",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          id="new-driverShiftAssignment-button"
          onClick={() => {
            setFormOpen(true);
            setSelectedDriverShiftAssignment(null);
          }}
        >
          {t("assignShift", "Assign Shift")}
        </Button>
      </Box>
      <SmartLynksTable
        query={query}
        dataKey="driverShiftAssignments"
        id="driverShiftAssignments-table"
        fields={fields}
        onRecordClick={(driverShiftAssignment) => {
          if (!hasWriteAccess) return;
          setFormOpen(true);
          setSelectedDriverShiftAssignment(driverShiftAssignment);
        }}
        disableSearch
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
      />
      <DriverShiftAssignmentFormModalContainer
        isOpen={formOpen}
        driverShiftAssignmentId={selectedDriverShiftAssignment?._id}
        onSaved={() => {
          setFormOpen(false);
          setSelectedDriverShiftAssignment(null);
          onRefresh();
        }}
        onClose={() => {
          setFormOpen(false);
          setSelectedDriverShiftAssignment(null);
        }}
        selectedDriverId={driverId}
      />
    </div>
  );
}
