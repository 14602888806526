import {
  GetReceiverReadingsQuery,
  useGetReceiverReadingsQuery,
  useGetReceiverForecastDetailsQuery,
  NewStorageFacilityReadingInput,
  useAddStorageFacilityReadingMutation,
} from "../../../graphql/generated";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import { differenceInWeeks } from "date-fns";
import ReceiverForecastVisualizer, {
  colors,
} from "../ReceiverForecastVisualizer/ReceiverForecastVisualizer";
import { ReceiverForecastDetailsData } from "../ReceiverForecastDetails/ReceiverForecastDetails";
import { formatPercentage } from "../../../utils/labels/formatPercentage";
import ShipmentsListContainer from "../../shipment/ShipmentsList";
import ReadingForm from "../ReadingForm/ReadingForm";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import { useDispatch } from "react-redux";
import { PlaylistAdd } from "@mui/icons-material";
import {
  AllSeriesType,
  ChartContainer,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
} from "@mui/x-charts";
import ForecastingNotificationsAlertsContainer from "../ForecastingNotificationsAlerts";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import StorageFacilityLevelBar from "./StorageFacilityLevelBar";
import { uniqBy } from "lodash";

type ForecastingReceiverDashboardProps = {
  receiverForecast: ReceiverForecastDetailsData;
  readings: GetReceiverReadingsQuery["storageFacilityReadings"]["data"];
  onReadingAdd: (reading: NewStorageFacilityReadingInput) => void;
};

enum DashboardTab {
  SALES = "SALES",
  READINGS = "READINGS",
  ORDERS = "ORDERS",
}

const ForecastingReceiverDashboard = ({
  receiverForecast,
  readings,
  onReadingAdd,
}: ForecastingReceiverDashboardProps) => {
  const storageFacility = receiverForecast.receiver?.storageFacilities?.[0];

  const [, setSelectedStorageFacility] =
    useState<typeof storageFacility | null>(null);

  const [tab, setTab] = useState<DashboardTab>(DashboardTab.SALES);

  const [localReading, setLocalReading] =
    useState<NewStorageFacilityReadingInput>({
      date: new Date(),
      level: 0,
      storageFacilityId: "",
      receiverId: receiverForecast.receiverId,
    });
  const [readingFormOpen, setReadingFormOpen] = useState(false);

  const last2WeeksReadings = readings.filter(
    (reading) => differenceInWeeks(new Date(), new Date(reading.date)) < 2
  );

  const dates = uniqBy(last2WeeksReadings, (reading) => reading.date).map(
    (reading) => reading.date
  );

  const readingLevelsSeries: AllSeriesType[] = receiverForecast.models.map(
    (model, index) => {
      const storageFacility =
        receiverForecast.receiver?.storageFacilities?.find(
          (sf) => sf.identifier === model.storageFacilityId
        );

      return {
        type: "line",
        yAxisKey: "readings",
        label: `${storageFacility?.identifier} - ${
          storageFacility?.commodity?.label || ""
        }`,
        connectNulls: true,
        data: dates.map((date) => {
          const reading = last2WeeksReadings.find(
            (reading) =>
              reading.date === date &&
              reading.storageFacilityId === storageFacility?.identifier
          );
          return reading?.level || null;
        }),
        color: colors[index % colors.length],
      };
    }
  );

  return (
    <Stack spacing={2}>
      <ForecastingNotificationsAlertsContainer
        receiverId={receiverForecast.receiverId}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Commodity</TableCell>
            <TableCell>Runout Date</TableCell>
            <TableCell>Trend</TableCell>
            <TableCell>Runout Date after next order</TableCell>
            <TableCell>Tank Levels</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receiverForecast.models.map((model) => {
            const storageFacility =
              receiverForecast.receiver?.storageFacilities?.find(
                (sf) => sf.identifier === model.storageFacilityId
              );
            if (!storageFacility) {
              return null;
            }

            return (
              <TableRow
                onClick={() => {
                  setSelectedStorageFacility(storageFacility);
                }}
              >
                <TableCell>{storageFacility.commodity?.label}</TableCell>
                <TableCell>
                  {storageFacility.runoutDate
                    ? formatDateTime(storageFacility?.runoutDate)
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {formatPercentage(storageFacility.currentSalesTrend)}
                </TableCell>
                <TableCell>
                  {storageFacility?.runoutDateAfterNextShipment
                    ? formatDateTime(
                        storageFacility?.runoutDateAfterNextShipment
                      )
                    : "N/A"}
                </TableCell>
                <TableCell>
                  <StorageFacilityLevelBar
                    storageFacility={storageFacility}
                    model={model}
                  />
                  <IconButton
                    onClick={() => {
                      setLocalReading({
                        date: new Date(),
                        level:
                          storageFacility.currentForecastedReading?.level || 0,
                        storageFacilityId: storageFacility.identifier,
                        receiverId: receiverForecast.receiverId,
                      });
                      setReadingFormOpen(true);
                    }}
                  >
                    <PlaylistAdd />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Tabs
        value={tab}
        onChange={(event, value) => {
          setTab(value);
        }}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="Sales" value={DashboardTab.SALES} />
        <Tab label="Readings" value={DashboardTab.READINGS} />
        <Tab label="Orders" value={DashboardTab.ORDERS} />
      </Tabs>

      {tab === DashboardTab.SALES && (
        <ReceiverForecastVisualizer
          readings={readings}
          receiverForecast={receiverForecast}
          showTankLevels={false}
        />
      )}

      {tab === DashboardTab.READINGS && (
        <ChartContainer
          series={readingLevelsSeries}
          width={800}
          height={400}
          xAxis={[
            {
              id: "dates",
              data: dates,
              scaleType: "band",
              valueFormatter: (value) =>
                formatDateTime(value, undefined, false),
            },
          ]}
          yAxis={[
            {
              id: "readings",
              scaleType: "linear",
            },
          ]}
        >
          <LinePlot />
          <ChartsXAxis label="Date" position="bottom" axisId="dates" />
          <ChartsYAxis label="Readings" position="left" axisId="readings" />
          <ChartsLegend />
          <ChartsTooltip />
        </ChartContainer>
      )}
      {tab === DashboardTab.ORDERS && (
        <ShipmentsListContainer
          receiverId={receiverForecast.receiverId}
          disableStatusTab
          excludePastShipments
          readonly
        />
      )}

      <Dialog
        open={readingFormOpen}
        onClose={() => setReadingFormOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Reading</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pt: 1,
            }}
          >
            <ReadingForm
              reading={localReading}
              onReadingChange={setLocalReading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onReadingAdd(localReading);
              setReadingFormOpen(false);
            }}
          >
            Add Reading
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

type ForecastingReceiverDashboardContainerProps = Omit<
  ForecastingReceiverDashboardProps,
  "readings" | "receiverForecast" | "onReadingAdd"
> & {
  receiverForecastId: string;
  receiverId: string;
};

export default function ForecastingReceiverDashboardContainer(
  props: ForecastingReceiverDashboardContainerProps
) {
  const readingsQuery = useGetReceiverReadingsQuery(
    {
      receiverId: props.receiverId,
    },
    {
      enabled: !!props.receiverId,
    }
  );
  const receiverForecastQuery = useGetReceiverForecastDetailsQuery(
    {
      id: props.receiverForecastId,
    },
    {
      refetchInterval: 1000 * 60,
    }
  );
  const dispatch = useDispatch();

  const addStorageFacilityReadingMutation =
    useAddStorageFacilityReadingMutation();
  if (!receiverForecastQuery.data?.receiverForecastById) {
    return null;
  }

  if (receiverForecastQuery.error) {
    return <ErrorMessage error={receiverForecastQuery.error} />;
  }

  return (
    <ForecastingReceiverDashboard
      {...props}
      readings={readingsQuery.data?.storageFacilityReadings.data || []}
      receiverForecast={receiverForecastQuery.data?.receiverForecastById}
      onReadingAdd={async (reading) => {
        try {
          await addStorageFacilityReadingMutation.mutateAsync({
            newStorageFacilityReadingData: reading,
          });
          readingsQuery.refetch();
          receiverForecastQuery.refetch();
        } catch (error) {
          console.error(error);
          dispatch(
            showDialog({
              title: "Error",
              description:
                "An error occurred while adding the reading." +
                  (addStorageFacilityReadingMutation.error as Error).message ||
                "Unknown error",
            })
          );
        }
      }}
    />
  );
}
