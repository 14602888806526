import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import {
  BillingMethod,
  BillingRuleConditionOperator,
  BillingRuleConditionTarget,
  GetCustomerDetailsQuery,
} from "../../../graphql/generated";
import ConditionForm from "./ConditionForm";
import { BillingRuleItem } from "../BillingRulesEditor/BillingRulesEditor";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import RateForm from "./RateForm";

type BillingRuleFormProps = {
  billingRule: BillingRuleItem;
  onChange: (billingRule: BillingRuleItem) => void;
  customer: GetCustomerDetailsQuery["customerById"];
};

const BillingRuleForm = ({
  billingRule,
  customer,
  onChange,
}: BillingRuleFormProps) => {
  const { t } = useTranslation("billingRules");
  return (
    <Grid container direction="row" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            pt: 1.4,
          }}
          color="primary"
          fontWeight="bold"
        >
          {t("form.when")}
        </Typography>
        <Stack spacing={1}>
          {billingRule.conditions?.map((condition, index) => (
            <Stack direction="column">
              <Typography variant="caption">
                {index === 0 ? "" : t("form.and")}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ConditionForm
                  customer={customer}
                  key={`condition-${index}`}
                  entity="billingRule"
                  condition={condition}
                  onChange={(condition) =>
                    onChange({
                      ...billingRule,
                      conditions: billingRule.conditions?.map((c, i) =>
                        i === index ? condition : c
                      ),
                    })
                  }
                />
                {billingRule.conditions.length > 1 ? (
                  <IconButton size="small">
                    <Clear
                      fontSize="small"
                      onClick={() => {
                        onChange({
                          ...billingRule,
                          conditions: billingRule.conditions?.filter(
                            (c, i) => i !== index
                          ),
                        });
                      }}
                    />
                  </IconButton>
                ) : null}
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Button
          onClick={() => {
            onChange({
              ...billingRule,
              conditions: [
                ...(billingRule.conditions || []),
                {
                  target: BillingRuleConditionTarget.TrailerType,
                  operator: BillingRuleConditionOperator.Equals,
                  value: null,
                },
              ],
            });
          }}
          sx={{
            alignSelf: "flex-start",
          }}
        >
          {t("form.+Condition")}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          ml: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            pt: 1.4,
          }}
          color="primary"
          fontWeight="bold"
        >
          {t("form.thenBill")}
        </Typography>

        <Stack spacing={1}>
          {billingRule.rates?.map((rate, index) => (
            <Stack direction="column">
              <Typography variant="caption">
                {index === 0 ? "" : t("form.and")}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <RateForm
                  key={`condition-${index}`}
                  rate={rate}
                  onChange={(rate) =>
                    onChange({
                      ...billingRule,
                      rates: billingRule.rates?.map((c, i) =>
                        i === index ? rate : c
                      ),
                    })
                  }
                  ruleType={billingRule.type}
                />
                {(billingRule.rates?.length || 0) > 1 ? (
                  <IconButton size="small">
                    <Clear
                      fontSize="small"
                      onClick={() => {
                        onChange({
                          ...billingRule,
                          rates: billingRule.rates?.filter(
                            (c, i) => i !== index
                          ),
                        });
                      }}
                    />
                  </IconButton>
                ) : null}
              </Stack>
            </Stack>
          ))}
          <Button
            onClick={() => {
              onChange({
                ...billingRule,
                rates: [
                  ...(billingRule.rates || []),
                  {
                    method: BillingMethod.FlatFee,
                    amount: 0,
                  },
                ],
              });
            }}
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {t("form.+Rate")}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BillingRuleForm;
